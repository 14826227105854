import { filter, cons, tryFind, map, empty } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { newGuid } from "../fable_modules/fable-library-js.4.19.2/Guid.js";
import { ShoppingCartPositionId, BillingMethod, BillingMethodInvoice, ShoppingCartId } from "../Shared/Shared.js";
import { ShoppingCartPosition, PackageUnit, QRCodeSize as QRCodeSize_1, ShoppingCart, ShoppingCartDeliveryAddress } from "../Shared/Shop.js";
import { Msg, State } from "./Types.js";
import { getAccountSettings } from "../Requests/Customer.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { tryParse } from "../fable_modules/fable-library-js.4.19.2/Int32.js";
import { FSharpRef } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { equals } from "../fable_modules/fable-library-js.4.19.2/Util.js";

export const emptyShoppingCart = new ShoppingCart(empty(), new ShoppingCartId(newGuid()), new ShoppingCartDeliveryAddress("", "", "", "", "de"), "", new BillingMethod(1, [new BillingMethodInvoice(false)]));

export function emptyShopingCartPosition(_arg) {
    return new ShoppingCartPosition(new ShoppingCartPositionId(newGuid()), new QRCodeSize_1(0, []), new PackageUnit(0, []), 1);
}

export function init() {
    return [new State(emptyShoppingCart, emptyShopingCartPosition(undefined), undefined), getAccountSettings((Item) => (new Msg(11, [Item])), (Item_1) => (new Msg(12, [Item_1])))];
}

export function update(msg, state) {
    let bind$0040_3, bind$0040_2, bind$0040_5, bind$0040_4, bind$0040_7, bind$0040_6, bind$0040_8, bind$0040_9, bind$0040_11, bind$0040_10, bind$0040_13, matchValue, bind$0040_14, bind$0040_1, bind$0040;
    switch (msg.tag) {
        case 3:
            return [new State((bind$0040_3 = state.ShoppingCart, new ShoppingCart(bind$0040_3.ShoppingCartPositions, bind$0040_3.ShoppingCartId, (bind$0040_2 = state.ShoppingCart.DeliveryAddress, new ShoppingCartDeliveryAddress(bind$0040_2.CompanyName, msg.fields[0], bind$0040_2.ZipCode, bind$0040_2.City, bind$0040_2.Country)), bind$0040_3.Comment, bind$0040_3.BillingMethod)), state.CurrentShoppingCartPosition, state.BillingData), Cmd_none()];
        case 4:
            return [new State((bind$0040_5 = state.ShoppingCart, new ShoppingCart(bind$0040_5.ShoppingCartPositions, bind$0040_5.ShoppingCartId, (bind$0040_4 = state.ShoppingCart.DeliveryAddress, new ShoppingCartDeliveryAddress(bind$0040_4.CompanyName, bind$0040_4.Street, msg.fields[0], bind$0040_4.City, bind$0040_4.Country)), bind$0040_5.Comment, bind$0040_5.BillingMethod)), state.CurrentShoppingCartPosition, state.BillingData), Cmd_none()];
        case 5:
            return [new State((bind$0040_7 = state.ShoppingCart, new ShoppingCart(bind$0040_7.ShoppingCartPositions, bind$0040_7.ShoppingCartId, (bind$0040_6 = state.ShoppingCart.DeliveryAddress, new ShoppingCartDeliveryAddress(bind$0040_6.CompanyName, bind$0040_6.Street, bind$0040_6.ZipCode, msg.fields[0], bind$0040_6.Country)), bind$0040_7.Comment, bind$0040_7.BillingMethod)), state.CurrentShoppingCartPosition, state.BillingData), Cmd_none()];
        case 7:
            return [new State(state.ShoppingCart, (bind$0040_8 = state.CurrentShoppingCartPosition, new ShoppingCartPosition(bind$0040_8.Id, msg.fields[0], bind$0040_8.PackageUnit, bind$0040_8.Amount)), state.BillingData), Cmd_none()];
        case 8:
            return [new State(state.ShoppingCart, (bind$0040_9 = state.CurrentShoppingCartPosition, new ShoppingCartPosition(bind$0040_9.Id, bind$0040_9.QRCodeSize, msg.fields[0], bind$0040_9.Amount)), state.BillingData), Cmd_none()];
        case 9: {
            const optionalPosId = msg.fields[0];
            let patternInput;
            let outArg = 0;
            patternInput = [tryParse(msg.fields[1], 511, false, 32, new FSharpRef(() => outArg, (v) => {
                outArg = (v | 0);
            })), outArg];
            const amount_1 = (patternInput[0] ? patternInput[1] : state.CurrentShoppingCartPosition.Amount) | 0;
            const amount_2 = ((amount_1 > 10) ? 10 : amount_1) | 0;
            if (optionalPosId == null) {
                return [new State(state.ShoppingCart, (bind$0040_11 = state.CurrentShoppingCartPosition, new ShoppingCartPosition(bind$0040_11.Id, bind$0040_11.QRCodeSize, bind$0040_11.PackageUnit, amount_2)), state.BillingData), Cmd_none()];
            }
            else {
                const posId = optionalPosId;
                return [new State((bind$0040_10 = state.ShoppingCart, new ShoppingCart(map((p) => {
                    if (equals(p.Id, posId)) {
                        return new ShoppingCartPosition(p.Id, p.QRCodeSize, p.PackageUnit, amount_2);
                    }
                    else {
                        return p;
                    }
                }, state.ShoppingCart.ShoppingCartPositions), bind$0040_10.ShoppingCartId, bind$0040_10.DeliveryAddress, bind$0040_10.Comment, bind$0040_10.BillingMethod)), state.CurrentShoppingCartPosition, state.BillingData), Cmd_none()];
            }
        }
        case 0:
            return [new State((bind$0040_13 = state.ShoppingCart, new ShoppingCart((matchValue = tryFind((p_1) => {
                if (equals(p_1.PackageUnit, state.CurrentShoppingCartPosition.PackageUnit)) {
                    return equals(p_1.QRCodeSize, state.CurrentShoppingCartPosition.QRCodeSize);
                }
                else {
                    return false;
                }
            }, state.ShoppingCart.ShoppingCartPositions), (matchValue == null) ? cons(state.CurrentShoppingCartPosition, state.ShoppingCart.ShoppingCartPositions) : map((p_2) => {
                if (equals(p_2.PackageUnit, state.CurrentShoppingCartPosition.PackageUnit) && equals(p_2.QRCodeSize, state.CurrentShoppingCartPosition.QRCodeSize)) {
                    const bind$0040_12 = state.CurrentShoppingCartPosition;
                    return new ShoppingCartPosition(bind$0040_12.Id, bind$0040_12.QRCodeSize, bind$0040_12.PackageUnit, state.CurrentShoppingCartPosition.Amount + p_2.Amount);
                }
                else {
                    return p_2;
                }
            }, state.ShoppingCart.ShoppingCartPositions)), bind$0040_13.ShoppingCartId, bind$0040_13.DeliveryAddress, bind$0040_13.Comment, bind$0040_13.BillingMethod)), emptyShopingCartPosition(undefined), state.BillingData), Cmd_none()];
        case 1:
            return [new State((bind$0040_14 = state.ShoppingCart, new ShoppingCart(filter((p_3) => !equals(p_3.Id, msg.fields[0]), state.ShoppingCart.ShoppingCartPositions), bind$0040_14.ShoppingCartId, bind$0040_14.DeliveryAddress, bind$0040_14.Comment, bind$0040_14.BillingMethod)), state.CurrentShoppingCartPosition, state.BillingData), Cmd_none()];
        case 6:
            return [state, Cmd_none()];
        case 10:
            return [new State(emptyShoppingCart, emptyShopingCartPosition(undefined), state.BillingData), Cmd_none()];
        case 11:
            return [state, Cmd_none()];
        case 12:
            return [state, Cmd_none()];
        default:
            return [new State((bind$0040_1 = state.ShoppingCart, new ShoppingCart(bind$0040_1.ShoppingCartPositions, bind$0040_1.ShoppingCartId, (bind$0040 = state.ShoppingCart.DeliveryAddress, new ShoppingCartDeliveryAddress(msg.fields[0], bind$0040.Street, bind$0040.ZipCode, bind$0040.City, bind$0040.Country)), bind$0040_1.Comment, bind$0040_1.BillingMethod)), state.CurrentShoppingCartPosition, state.BillingData), Cmd_none()];
    }
}

