import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { CustomField, PropertyList } from "./PropertyListWidget.js";
import { sortWith, map } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { unwrap, map as map_1, defaultArg } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { toString } from "../fable_modules/fable-library-js.4.19.2/Date.js";
import { priceToString } from "../Common.js";
import { parse } from "../fable_modules/fable-library-js.4.19.2/Int32.js";
import { sortAsc } from "../SharedComponents/NaturalOrder.js";

export function CustomPropertiesPropertyList(props) {
    const t = useTranslation()[0];
    return createElement(PropertyList, {
        Properties: map((customProperty) => {
            const matchValue = customProperty.Type;
            switch (matchValue) {
                case "enum":
                    return {
                        Label: customProperty.Label,
                        TestId: `custom-property-enum-${customProperty.Label}`,
                        Value: defaultArg(customProperty.TextValue, ""),
                    };
                case "multiEnum":
                    return {
                        Label: customProperty.Label,
                        TestId: `custom-property-multi-enum-${customProperty.Label}`,
                        Value: defaultArg(customProperty.TextValue, ""),
                    };
                case "bool":
                    return {
                        Label: customProperty.Label,
                        TestId: `custom-property-bool-${customProperty.Label}`,
                        Value: defaultArg(map_1((value_3) => {
                            if (value_3) {
                                return t("general.yes");
                            }
                            else {
                                return t("general.no");
                            }
                        }, customProperty.BoolValue), ""),
                    };
                case "date":
                    return {
                        Label: customProperty.Label,
                        TestId: `custom-property-date-${customProperty.Label}`,
                        Value: defaultArg(map_1((value_5) => toString(value_5, "dd.MM.yyyy"), customProperty.DateValue), ""),
                    };
                case "url":
                    return {
                        CustomField: new CustomField(0, []),
                        Label: customProperty.Label,
                        TestId: `custom-property-url-${customProperty.Label}`,
                        Value: defaultArg(customProperty.TextValue, ""),
                    };
                case "currency":
                    return {
                        Label: customProperty.Label,
                        TestId: `custom-property-currency-${customProperty.Label}`,
                        Value: defaultArg(map_1((arg) => priceToString(parse(arg, 511, false, 32)), customProperty.TextValue), ""),
                    };
                default:
                    return {
                        Label: customProperty.Label,
                        TestId: `custom-property-text-${customProperty.Label}`,
                        Value: defaultArg(customProperty.TextValue, ""),
                    };
            }
        }, sortWith((globalPropertyA, globalPropertyB) => sortAsc(globalPropertyA.Label, globalPropertyB.Label), props.CustomProperties)),
        Title: unwrap(props.Title),
    });
}

