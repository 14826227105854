import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { option_type, record_type, lambda_type, unit_type, union_type, list_type, bool_type, class_type, int32_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ReminderId as ReminderId_1, ReminderDateDto_$reflection, AdditionalReminderDefinitionDto, CustomIntervalDefinition, ReminderInterval, ToolId_$reflection, PostResponse$1_$reflection, EmptyResponse_$reflection, ReminderInterval_$reflection } from "../../Shared/Shared.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none, Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { tryFind, map, empty, singleton, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { ToolConfirmReminderDto, UpdateReminderDto, ToolConfirmReminderDto_$reflection, UpdateReminderDto_$reflection, ToolReminderUpdateResult_$reflection, AddReminderDto, AddReminderDto_$reflection } from "../../Shared/Tool.js";
import { now as now_1, timeOfDay, add, addDays, addYears, addMonths, utcNow } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { parse, newGuid } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { Cmd_getReminderNameSuggestions } from "../../Requests/Tool.js";
import { map as map_1, unwrap, defaultArg } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { createObj, equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join, isNullOrWhiteSpace, isNullOrEmpty } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { Cmd_successToast } from "../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { InformationAlert } from "../../Components/Alert.js";
import { RadioButton } from "../../Components/RadioButton.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { AutoSuggestInput } from "../../Components/Input.js";
import { DatePicker } from "../../Components/DatePicker.js";
import { Select } from "../../Components/Select.js";
import { DayWeekMonthYearInput } from "../../Widgets/DayWeekMonthYearInput.js";
import { AdditionalReminderWidget } from "../../Widgets/AdditionalReminderWidget.js";
import { TextArea } from "../../Components/TextArea.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { ConfirmationDialog, Dialog } from "../../Components/Dialog.js";
import { TextButton } from "../../Components/Button.js";
import { unwrapAdditionalReminderId, unwrapToolId } from "../../Shared/Helper.js";
import { toISOString } from "../../Common.js";
import { TextValue } from "../../Components/Text.js";
import { Checkbox } from "../../Components/Checkbox.js";

class NewReminder_Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetAdditionalReminderName", "SetAdditionalReminderInterval", "SetCustomIntervalValue", "SetReminderInterval", "SetDate", "SetTitle", "SetDescription", "SetIsNormalReminder", "ReminderSaved", "SaveReminderDate", "ReminderNameSuggestionsFetched", "FetchError"];
    }
}

function NewReminder_Msg_$reflection() {
    return union_type("Tools.AddReminderDialog.NewReminder.Msg", [], NewReminder_Msg, () => [[["id", string_type], ["name", string_type]], [["id", string_type], ["interval", string_type], ["value", int32_type]], [["Item1", string_type], ["value", int32_type]], [["Item", ReminderInterval_$reflection()]], [["Item", class_type("System.DateTime")]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", PostResponse$1_$reflection(EmptyResponse_$reflection())]], [], [["Item", list_type(string_type)]], [["Item", class_type("System.Exception")]]]);
}

function NewReminder_postAddReminderCmd(toolId, dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(EmptyResponse_$reflection())));
    })))), [`/api/tools/${toolId.fields[0]}/reminder`, toString(0, Auto_generateBoxedEncoder_437914C6(AddReminderDto_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new NewReminder_Msg(8, [Item])), (Item_1) => (new NewReminder_Msg(11, [Item_1])));
}

export class NewReminder_State extends Record {
    constructor(ToolId, IsLoading, SuccessCallback, Dto, OnClose, IsNormalReminder, IsTitleInvalid, ReminderNameSuggestions) {
        super();
        this.ToolId = ToolId;
        this.IsLoading = IsLoading;
        this.SuccessCallback = SuccessCallback;
        this.Dto = Dto;
        this.OnClose = OnClose;
        this.IsNormalReminder = IsNormalReminder;
        this.IsTitleInvalid = IsTitleInvalid;
        this.ReminderNameSuggestions = ReminderNameSuggestions;
    }
}

export function NewReminder_State_$reflection() {
    return record_type("Tools.AddReminderDialog.NewReminder.State", [], NewReminder_State, () => [["ToolId", ToolId_$reflection()], ["IsLoading", bool_type], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["Dto", AddReminderDto_$reflection()], ["OnClose", lambda_type(unit_type, unit_type)], ["IsNormalReminder", bool_type], ["IsTitleInvalid", bool_type], ["ReminderNameSuggestions", list_type(string_type)]]);
}

function NewReminder_init(props) {
    return [new NewReminder_State(props.ToolId, false, props.SuccessCallback, new AddReminderDto(utcNow(), "", "", new ReminderInterval(4, []), undefined, singleton(new AdditionalReminderDefinitionDto(newGuid(), "", new CustomIntervalDefinition("day", 3)))), props.OnClose, true, false, empty()), Cmd_getReminderNameSuggestions((Item) => (new NewReminder_Msg(10, [Item])), (Item_1) => (new NewReminder_Msg(11, [Item_1])))];
}

function NewReminder_update(msg, state) {
    let bind$0040_1, bind$0040_2, bind$0040_3, bind$0040_4, bind$0040_5, bind$0040_6, bind$0040_7, bind$0040;
    switch (msg.tag) {
        case 1:
            return [new NewReminder_State(state.ToolId, state.IsLoading, state.SuccessCallback, (bind$0040_1 = state.Dto, new AddReminderDto(bind$0040_1.Date, bind$0040_1.Title, bind$0040_1.Description, bind$0040_1.Interval, bind$0040_1.CustomInterval, map((ar_1) => {
                if (ar_1.Id === msg.fields[0]) {
                    return new AdditionalReminderDefinitionDto(ar_1.Id, ar_1.Name, new CustomIntervalDefinition(msg.fields[1], msg.fields[2]));
                }
                else {
                    return ar_1;
                }
            }, defaultArg(state.Dto.AdditionalReminders, empty())))), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderNameSuggestions), Cmd_none()];
        case 2:
            return [new NewReminder_State(state.ToolId, state.IsLoading, state.SuccessCallback, (bind$0040_2 = state.Dto, new AddReminderDto(bind$0040_2.Date, bind$0040_2.Title, bind$0040_2.Description, bind$0040_2.Interval, new CustomIntervalDefinition(msg.fields[0], msg.fields[1]), bind$0040_2.AdditionalReminders)), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderNameSuggestions), Cmd_none()];
        case 3: {
            const value_4 = msg.fields[0];
            return [new NewReminder_State(state.ToolId, state.IsLoading, state.SuccessCallback, (bind$0040_3 = state.Dto, new AddReminderDto(bind$0040_3.Date, bind$0040_3.Title, bind$0040_3.Description, value_4, !equals(value_4, new ReminderInterval(6, [])) ? undefined : (new CustomIntervalDefinition("day", 0)), bind$0040_3.AdditionalReminders)), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderNameSuggestions), Cmd_none()];
        }
        case 5:
            return [new NewReminder_State(state.ToolId, state.IsLoading, state.SuccessCallback, (bind$0040_4 = state.Dto, new AddReminderDto(bind$0040_4.Date, msg.fields[0], bind$0040_4.Description, bind$0040_4.Interval, bind$0040_4.CustomInterval, bind$0040_4.AdditionalReminders)), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderNameSuggestions), Cmd_none()];
        case 4:
            return [new NewReminder_State(state.ToolId, state.IsLoading, state.SuccessCallback, (bind$0040_5 = state.Dto, new AddReminderDto(msg.fields[0], bind$0040_5.Title, bind$0040_5.Description, bind$0040_5.Interval, bind$0040_5.CustomInterval, bind$0040_5.AdditionalReminders)), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderNameSuggestions), Cmd_none()];
        case 6:
            return [new NewReminder_State(state.ToolId, state.IsLoading, state.SuccessCallback, (bind$0040_6 = state.Dto, new AddReminderDto(bind$0040_6.Date, bind$0040_6.Title, msg.fields[0], bind$0040_6.Interval, bind$0040_6.CustomInterval, bind$0040_6.AdditionalReminders)), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderNameSuggestions), Cmd_none()];
        case 7:
            return [new NewReminder_State(state.ToolId, state.IsLoading, state.SuccessCallback, state.Dto, state.OnClose, msg.fields[0], state.IsTitleInvalid, state.ReminderNameSuggestions), Cmd_none()];
        case 9:
            if (isNullOrEmpty(state.Dto.Title) ? true : isNullOrWhiteSpace(state.Dto.Title)) {
                return [new NewReminder_State(state.ToolId, state.IsLoading, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, true, state.ReminderNameSuggestions), Cmd_none()];
            }
            else {
                return [new NewReminder_State(state.ToolId, true, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderNameSuggestions), NewReminder_postAddReminderCmd(state.ToolId, state.IsNormalReminder ? ((bind$0040_7 = state.Dto, new AddReminderDto(bind$0040_7.Date, bind$0040_7.Title, bind$0040_7.Description, undefined, bind$0040_7.CustomInterval, bind$0040_7.AdditionalReminders))) : state.Dto)];
            }
        case 8:
            return [new NewReminder_State(state.ToolId, false, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderNameSuggestions), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                state.SuccessCallback();
            }), Cmd_ofEffect((_arg_1) => {
                state.OnClose();
            }), Cmd_successToast("tool.dialog_add_reminder_success")]))];
        case 10:
            return [new NewReminder_State(state.ToolId, state.IsLoading, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, msg.fields[0]), Cmd_none()];
        case 11:
            return [new NewReminder_State(state.ToolId, false, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderNameSuggestions), Cmd_none()];
        default:
            return [new NewReminder_State(state.ToolId, state.IsLoading, state.SuccessCallback, (bind$0040 = state.Dto, new AddReminderDto(bind$0040.Date, bind$0040.Title, bind$0040.Description, bind$0040.Interval, bind$0040.CustomInterval, map((ar) => {
                if (ar.Id === msg.fields[0]) {
                    return new AdditionalReminderDefinitionDto(ar.Id, msg.fields[1], ar.Interval);
                }
                else {
                    return ar;
                }
            }, defaultArg(state.Dto.AdditionalReminders, empty())))), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderNameSuggestions), Cmd_none()];
    }
}

function NewReminder_DialogBody(props) {
    let elems_1;
    const t = useTranslation()[0];
    const formState = props.State.IsLoading ? "disabled" : "enabled";
    const intervalOptions = ofArray([{
        label: t("reminder_dialog.weekly"),
        value: new ReminderInterval(0, []),
    }, {
        label: t("reminder_dialog.monthly"),
        value: new ReminderInterval(1, []),
    }, {
        label: t("reminder_dialog.quarterly"),
        value: new ReminderInterval(2, []),
    }, {
        label: t("reminder_dialog.halfyearly"),
        value: new ReminderInterval(3, []),
    }, {
        label: t("reminder_dialog.yearly"),
        value: new ReminderInterval(4, []),
    }, {
        label: t("reminder_dialog.twoyearly"),
        value: new ReminderInterval(5, []),
    }, {
        label: t("reminder_dialog.user_defined"),
        value: new ReminderInterval(6, []),
    }]);
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4", "w-[30rem]"])], (elems_1 = toList(delay(() => append(props.State.IsNormalReminder ? singleton_1(defaultOf()) : singleton_1(createElement(InformationAlert, {
        Label: "reminder_dialog.interval_reminder_info",
    })), delay(() => {
        let elems;
        return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-2"])], (elems = [RadioButton({
            ComponentState: formState,
            Label: t("reminder_dialog.normal_reminder"),
            OnSelect: (_arg) => {
                props.Dispatch(new NewReminder_Msg(7, [true]));
            },
            Selected: props.State.IsNormalReminder,
        }), RadioButton({
            ComponentState: formState,
            Label: t("reminder_dialog.interval_reminder"),
            OnSelect: (_arg_1) => {
                props.Dispatch(new NewReminder_Msg(7, [false]));
            },
            Selected: !props.State.IsNormalReminder,
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let dependencies_1;
            return append(singleton_1((dependencies_1 = [props.State.Dto.Title, props.ReminderSuggestions, props.State.IsTitleInvalid, formState], reactApi.useMemo(() => createElement(AutoSuggestInput, {
                ComponentState: formState,
                Label: t("general.name"),
                OnChange: (value_4) => {
                    props.Dispatch(new NewReminder_Msg(5, [value_4]));
                },
                Suggestions: props.ReminderSuggestions,
                TestId: "",
                ValidationMessage: unwrap(props.State.IsTitleInvalid ? t("tool.reminder_invalid_msg") : undefined),
                Value: props.State.Dto.Title,
            }), dependencies_1))), delay(() => append(singleton_1(createElement(DatePicker, {
                ComponentState: formState,
                IsClearable: false,
                Label: props.State.IsNormalReminder ? t("general.date") : t("reminder_dialog.first_execution"),
                OnChange: (date) => {
                    if (date == null) {
                    }
                    else {
                        props.Dispatch(new NewReminder_Msg(4, [date]));
                    }
                },
                TestId: "",
                Value: props.State.Dto.Date,
            })), delay(() => {
                let matchValue, interval;
                return append(props.State.IsNormalReminder ? singleton_1(defaultOf()) : singleton_1(createElement(Select, {
                    ComponentState: formState,
                    IsClearable: false,
                    Label: t("reminder_dialog.interval"),
                    NoOptionsMessage: "",
                    OnChange: (option) => {
                        if (option == null) {
                        }
                        else {
                            props.Dispatch(new NewReminder_Msg(3, [option.value]));
                        }
                    },
                    Options: intervalOptions,
                    PlaceholderKey: "",
                    TestId: "",
                    Value: unwrap((matchValue = props.State.Dto.Interval, (matchValue == null) ? {
                        label: t("reminder_dialog.yearly"),
                        value: new ReminderInterval(4, []),
                    } : ((interval = matchValue, tryFind((option_2) => equals(option_2.value, interval), intervalOptions))))),
                })), delay(() => {
                    let matchValue_1, matchValue_2, Value_3;
                    return append((matchValue_1 = props.State.Dto.Interval, (matchValue_1 == null) ? (empty_1()) : ((matchValue_2 = matchValue_1, (matchValue_2.tag === 1) ? (empty_1()) : ((matchValue_2.tag === 2) ? (empty_1()) : ((matchValue_2.tag === 3) ? (empty_1()) : ((matchValue_2.tag === 4) ? (empty_1()) : ((matchValue_2.tag === 5) ? (empty_1()) : ((matchValue_2.tag === 6) ? singleton_1(createElement(DayWeekMonthYearInput, (Value_3 = (defaultArg(map_1((ci) => ci.Value, props.State.Dto.CustomInterval), 0) | 0), {
                        ComponentState: formState,
                        InitialInterval: unwrap(map_1((ci_1) => ci_1.Interval, props.State.Dto.CustomInterval)),
                        IsBefore: false,
                        SetValue: (value_6) => {
                            let tupledArg;
                            props.Dispatch((tupledArg = value_6, new NewReminder_Msg(2, [tupledArg[0], tupledArg[1]])));
                        },
                        Value: Value_3,
                    }))) : (empty_1()))))))))), delay(() => append(singleton_1(createElement(AdditionalReminderWidget, {
                        AdditionlReminders: defaultArg(props.State.Dto.AdditionalReminders, empty()),
                        ComponentState: formState,
                        SetInterval: (tupledArg_2) => {
                            props.Dispatch(new NewReminder_Msg(1, [tupledArg_2[0], tupledArg_2[1], tupledArg_2[2]]));
                        },
                        SetName: (tupledArg_1) => {
                            props.Dispatch(new NewReminder_Msg(0, [tupledArg_1[0], tupledArg_1[1]]));
                        },
                    })), delay(() => singleton_1(createElement(TextArea, {
                        ComponentState: formState,
                        Label: t("general.description"),
                        OnChange: (value_11) => {
                            props.Dispatch(new NewReminder_Msg(6, [value_11]));
                        },
                        TestId: "tool-add-reminder-description-test-id",
                        Value: props.State.Dto.Description,
                    }))))));
                }));
            }))));
        }));
    })))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function NewReminder_OpenDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init = NewReminder_init({
        OnClose: props.OnClose,
        SuccessCallback: props.SuccessCallback,
        ToolId: props.ToolId,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, NewReminder_update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    return createElement(Dialog, (Title = t("tool.create_reminder"), {
        Body: singleton(createElement(NewReminder_DialogBody, {
            Dispatch: dispatch,
            ReminderSuggestions: state_1.ReminderNameSuggestions,
            State: state_1,
            SuccessCallback: props.SuccessCallback,
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-reservation-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: state_1.IsLoading ? "disabled" : "enabled",
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new NewReminder_Msg(9, []));
            },
            TestId: "save-reminder-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

class EditReminder_Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetAdditionalReminderName", "SetAdditionalReminderInterval", "SetCustomIntervalValue", "SetReminderInterval", "SetDate", "SetTitle", "SetDescription", "SetIsNormalReminder", "ReminderSaved", "SaveReminderDate", "ReminderNameSuggestionsFetched", "ConfirmToolReminder", "ToolReminderConfirmed", "FetchError"];
    }
}

function EditReminder_Msg_$reflection() {
    return union_type("Tools.AddReminderDialog.EditReminder.Msg", [], EditReminder_Msg, () => [[["id", string_type], ["name", string_type]], [["id", string_type], ["interval", string_type], ["value", int32_type]], [["Item1", string_type], ["value", int32_type]], [["Item", ReminderInterval_$reflection()]], [["Item", class_type("System.DateTime")]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", PostResponse$1_$reflection(EmptyResponse_$reflection())]], [], [["Item", list_type(string_type)]], [], [["Item", PostResponse$1_$reflection(ToolReminderUpdateResult_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

function EditReminder_putUpdateReminderCmd(toolId, dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(EmptyResponse_$reflection())));
    })))), [`/api/tools/${toolId.fields[0]}/reminder`, toString(0, Auto_generateBoxedEncoder_437914C6(UpdateReminderDto_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new EditReminder_Msg(8, [Item])), (Item_1) => (new EditReminder_Msg(13, [Item_1])));
}

function EditReminder_confirmReminderCmd(toolId, dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(ToolReminderUpdateResult_$reflection())));
    })))), [`/api/tools/${unwrapToolId(toolId)}/reminder/confirm`, toString(0, Auto_generateBoxedEncoder_437914C6(ToolConfirmReminderDto_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new EditReminder_Msg(12, [Item])), (Item_1) => (new EditReminder_Msg(13, [Item_1])));
}

export class EditReminder_State extends Record {
    constructor(ToolId, IsLoading, SuccessCallback, Dto, OnClose, IsNormalReminder, IsTitleInvalid, ReminderId, ReminderNameSuggestions) {
        super();
        this.ToolId = ToolId;
        this.IsLoading = IsLoading;
        this.SuccessCallback = SuccessCallback;
        this.Dto = Dto;
        this.OnClose = OnClose;
        this.IsNormalReminder = IsNormalReminder;
        this.IsTitleInvalid = IsTitleInvalid;
        this.ReminderId = ReminderId;
        this.ReminderNameSuggestions = ReminderNameSuggestions;
    }
}

export function EditReminder_State_$reflection() {
    return record_type("Tools.AddReminderDialog.EditReminder.State", [], EditReminder_State, () => [["ToolId", ToolId_$reflection()], ["IsLoading", bool_type], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["Dto", UpdateReminderDto_$reflection()], ["OnClose", lambda_type(unit_type, unit_type)], ["IsNormalReminder", bool_type], ["IsTitleInvalid", bool_type], ["ReminderId", string_type], ["ReminderNameSuggestions", list_type(string_type)]]);
}

function EditReminder_init(props) {
    let IsNormalReminder;
    return [(IsNormalReminder = (props.Reminder.Interval == null), new EditReminder_State(props.ToolId, false, props.SuccessCallback, new UpdateReminderDto(props.Reminder.Id, props.Reminder.Date, props.Reminder.Title, props.Reminder.Description, props.Reminder.Interval, props.Reminder.CustomInterval, map((ar) => (new AdditionalReminderDefinitionDto(unwrapAdditionalReminderId(ar.Id), ar.Name, ar.Interval)), props.Reminder.AdditionalReminders)), props.OnClose, IsNormalReminder, false, props.Reminder.Id, empty())), Cmd_getReminderNameSuggestions((Item) => (new EditReminder_Msg(10, [Item])), (Item_1) => (new EditReminder_Msg(13, [Item_1])))];
}

function EditReminder_update(msg, state) {
    let bind$0040_1, bind$0040_2, bind$0040_3, bind$0040_4, bind$0040_5, bind$0040_6, bind$0040_7, bind$0040_8, bind$0040;
    switch (msg.tag) {
        case 1:
            return [new EditReminder_State(state.ToolId, state.IsLoading, state.SuccessCallback, (bind$0040_1 = state.Dto, new UpdateReminderDto(bind$0040_1.ReminderId, bind$0040_1.Date, bind$0040_1.Title, bind$0040_1.Description, bind$0040_1.Interval, bind$0040_1.CustomInterval, map((ar_1) => {
                if (ar_1.Id === msg.fields[0]) {
                    return new AdditionalReminderDefinitionDto(ar_1.Id, ar_1.Name, new CustomIntervalDefinition(msg.fields[1], msg.fields[2]));
                }
                else {
                    return ar_1;
                }
            }, defaultArg(state.Dto.AdditionalReminders, empty())))), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderId, state.ReminderNameSuggestions), Cmd_none()];
        case 2:
            return [new EditReminder_State(state.ToolId, state.IsLoading, state.SuccessCallback, (bind$0040_2 = state.Dto, new UpdateReminderDto(bind$0040_2.ReminderId, bind$0040_2.Date, bind$0040_2.Title, bind$0040_2.Description, bind$0040_2.Interval, new CustomIntervalDefinition(msg.fields[0], msg.fields[1]), bind$0040_2.AdditionalReminders)), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderId, state.ReminderNameSuggestions), Cmd_none()];
        case 3: {
            const value_4 = msg.fields[0];
            return [new EditReminder_State(state.ToolId, state.IsLoading, state.SuccessCallback, (bind$0040_3 = state.Dto, new UpdateReminderDto(bind$0040_3.ReminderId, bind$0040_3.Date, bind$0040_3.Title, bind$0040_3.Description, value_4, !equals(value_4, new ReminderInterval(6, [])) ? undefined : (new CustomIntervalDefinition("day", 0)), bind$0040_3.AdditionalReminders)), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderId, state.ReminderNameSuggestions), Cmd_none()];
        }
        case 5:
            return [new EditReminder_State(state.ToolId, state.IsLoading, state.SuccessCallback, (bind$0040_4 = state.Dto, new UpdateReminderDto(bind$0040_4.ReminderId, bind$0040_4.Date, msg.fields[0], bind$0040_4.Description, bind$0040_4.Interval, bind$0040_4.CustomInterval, bind$0040_4.AdditionalReminders)), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderId, state.ReminderNameSuggestions), Cmd_none()];
        case 4:
            return [new EditReminder_State(state.ToolId, state.IsLoading, state.SuccessCallback, (bind$0040_5 = state.Dto, new UpdateReminderDto(bind$0040_5.ReminderId, msg.fields[0], bind$0040_5.Title, bind$0040_5.Description, bind$0040_5.Interval, bind$0040_5.CustomInterval, bind$0040_5.AdditionalReminders)), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderId, state.ReminderNameSuggestions), Cmd_none()];
        case 6:
            return [new EditReminder_State(state.ToolId, state.IsLoading, state.SuccessCallback, (bind$0040_6 = state.Dto, new UpdateReminderDto(bind$0040_6.ReminderId, bind$0040_6.Date, bind$0040_6.Title, msg.fields[0], bind$0040_6.Interval, bind$0040_6.CustomInterval, bind$0040_6.AdditionalReminders)), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderId, state.ReminderNameSuggestions), Cmd_none()];
        case 7:
            return [new EditReminder_State(state.ToolId, state.IsLoading, state.SuccessCallback, state.Dto, state.OnClose, msg.fields[0], state.IsTitleInvalid, state.ReminderId, state.ReminderNameSuggestions), Cmd_none()];
        case 9:
            if (isNullOrEmpty(state.Dto.Title) ? true : isNullOrWhiteSpace(state.Dto.Title)) {
                return [new EditReminder_State(state.ToolId, state.IsLoading, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, true, state.ReminderId, state.ReminderNameSuggestions), Cmd_none()];
            }
            else {
                return [new EditReminder_State(state.ToolId, true, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderId, state.ReminderNameSuggestions), EditReminder_putUpdateReminderCmd(state.ToolId, state.IsNormalReminder ? ((bind$0040_7 = state.Dto, new UpdateReminderDto(bind$0040_7.ReminderId, bind$0040_7.Date, bind$0040_7.Title, bind$0040_7.Description, undefined, bind$0040_7.CustomInterval, bind$0040_7.AdditionalReminders))) : ((bind$0040_8 = state.Dto, new UpdateReminderDto(bind$0040_8.ReminderId, bind$0040_8.Date, bind$0040_8.Title, bind$0040_8.Description, (state.Dto.Interval == null) ? (new ReminderInterval(4, [])) : state.Dto.Interval, bind$0040_8.CustomInterval, bind$0040_8.AdditionalReminders))))];
            }
        case 8:
            return [new EditReminder_State(state.ToolId, false, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderId, state.ReminderNameSuggestions), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                state.SuccessCallback();
            }), Cmd_ofEffect((_arg_1) => {
                state.OnClose();
            }), Cmd_successToast("tool.dialog_add_reminder_success")]))];
        case 10:
            return [new EditReminder_State(state.ToolId, state.IsLoading, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderId, msg.fields[0]), Cmd_none()];
        case 11:
            return [new EditReminder_State(state.ToolId, true, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderId, state.ReminderNameSuggestions), EditReminder_confirmReminderCmd(state.ToolId, new ToolConfirmReminderDto(false, state.ReminderId, false, false, undefined, undefined, ""))];
        case 12:
            return [new EditReminder_State(state.ToolId, false, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderId, state.ReminderNameSuggestions), Cmd_batch(ofArray([Cmd_ofEffect((_arg_2) => {
                state.SuccessCallback();
            }), Cmd_ofEffect((_arg_3) => {
                state.OnClose();
            }), Cmd_successToast("tool.dialog_mark_appointment_as_done_success")]))];
        case 13:
            return [new EditReminder_State(state.ToolId, false, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderId, state.ReminderNameSuggestions), Cmd_none()];
        default:
            return [new EditReminder_State(state.ToolId, state.IsLoading, state.SuccessCallback, (bind$0040 = state.Dto, new UpdateReminderDto(bind$0040.ReminderId, bind$0040.Date, bind$0040.Title, bind$0040.Description, bind$0040.Interval, bind$0040.CustomInterval, map((ar) => {
                if (ar.Id === msg.fields[0]) {
                    return new AdditionalReminderDefinitionDto(ar.Id, msg.fields[1], ar.Interval);
                }
                else {
                    return ar;
                }
            }, defaultArg(state.Dto.AdditionalReminders, empty())))), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.ReminderId, state.ReminderNameSuggestions), Cmd_none()];
    }
}

function EditReminder_DialogBody(props) {
    let elems_1;
    const t = useTranslation()[0];
    const formState = props.State.IsLoading ? "disabled" : "enabled";
    const intervalOptions = ofArray([{
        label: t("reminder_dialog.weekly"),
        value: new ReminderInterval(0, []),
    }, {
        label: t("reminder_dialog.monthly"),
        value: new ReminderInterval(1, []),
    }, {
        label: t("reminder_dialog.quarterly"),
        value: new ReminderInterval(2, []),
    }, {
        label: t("reminder_dialog.halfyearly"),
        value: new ReminderInterval(3, []),
    }, {
        label: t("reminder_dialog.yearly"),
        value: new ReminderInterval(4, []),
    }, {
        label: t("reminder_dialog.twoyearly"),
        value: new ReminderInterval(5, []),
    }, {
        label: t("reminder_dialog.user_defined"),
        value: new ReminderInterval(6, []),
    }]);
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4", "w-[30rem]"])], (elems_1 = toList(delay(() => append(props.State.IsNormalReminder ? singleton_1(defaultOf()) : singleton_1(createElement(InformationAlert, {
        Label: "reminder_dialog.interval_reminder_info",
    })), delay(() => {
        let elems;
        return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-2"])], (elems = [RadioButton({
            ComponentState: formState,
            Label: t("reminder_dialog.normal_reminder"),
            OnSelect: (_arg) => {
                props.Dispatch(new EditReminder_Msg(7, [true]));
            },
            Selected: props.State.IsNormalReminder,
        }), RadioButton({
            ComponentState: formState,
            Label: t("reminder_dialog.interval_reminder"),
            OnSelect: (_arg_1) => {
                props.Dispatch(new EditReminder_Msg(7, [false]));
            },
            Selected: !props.State.IsNormalReminder,
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let dependencies_1;
            return append(singleton_1((dependencies_1 = [props.State.Dto.Title, props.ReminderSuggestions, props.State.IsTitleInvalid, formState], reactApi.useMemo(() => createElement(AutoSuggestInput, {
                ComponentState: formState,
                Label: t("general.name"),
                OnChange: (value_4) => {
                    props.Dispatch(new EditReminder_Msg(5, [value_4]));
                },
                Suggestions: props.ReminderSuggestions,
                TestId: "tool-add-reminder-dialog-reminder-suggest-test-id",
                ValidationMessage: unwrap(props.State.IsTitleInvalid ? t("tool.reminder_invalid_msg") : undefined),
                Value: props.State.Dto.Title,
            }), dependencies_1))), delay(() => append(singleton_1(createElement(DatePicker, {
                ComponentState: formState,
                IsClearable: false,
                Label: props.State.IsNormalReminder ? t("general.date") : t("reminder_dialog.first_execution"),
                OnChange: (date) => {
                    if (date == null) {
                    }
                    else {
                        props.Dispatch(new EditReminder_Msg(4, [date]));
                    }
                },
                TestId: "tool-add-reminder-dialog-first-execution-test-id",
                Value: props.State.Dto.Date,
            })), delay(() => {
                let matchValue, interval;
                return append(props.State.IsNormalReminder ? singleton_1(defaultOf()) : singleton_1(createElement(Select, {
                    ComponentState: formState,
                    IsClearable: false,
                    Label: t("reminder_dialog.interval"),
                    NoOptionsMessage: "",
                    OnChange: (option) => {
                        if (option == null) {
                        }
                        else {
                            props.Dispatch(new EditReminder_Msg(3, [option.value]));
                        }
                    },
                    Options: intervalOptions,
                    PlaceholderKey: "",
                    TestId: "",
                    Value: unwrap((matchValue = props.State.Dto.Interval, (matchValue == null) ? {
                        label: t("reminder_dialog.yearly"),
                        value: new ReminderInterval(4, []),
                    } : ((interval = matchValue, tryFind((option_2) => equals(option_2.value, interval), intervalOptions))))),
                })), delay(() => {
                    let matchValue_1, matchValue_2, Value_3;
                    return append((matchValue_1 = props.State.Dto.Interval, (matchValue_1 == null) ? (empty_1()) : ((matchValue_2 = matchValue_1, (matchValue_2.tag === 1) ? (empty_1()) : ((matchValue_2.tag === 2) ? (empty_1()) : ((matchValue_2.tag === 3) ? (empty_1()) : ((matchValue_2.tag === 4) ? (empty_1()) : ((matchValue_2.tag === 5) ? (empty_1()) : ((matchValue_2.tag === 6) ? singleton_1(createElement(DayWeekMonthYearInput, (Value_3 = (defaultArg(map_1((ci) => ci.Value, props.State.Dto.CustomInterval), 0) | 0), {
                        ComponentState: formState,
                        InitialInterval: unwrap(map_1((ci_1) => ci_1.Interval, props.State.Dto.CustomInterval)),
                        IsBefore: false,
                        SetValue: (value_6) => {
                            let tupledArg;
                            props.Dispatch((tupledArg = value_6, new EditReminder_Msg(2, [tupledArg[0], tupledArg[1]])));
                        },
                        Value: Value_3,
                    }))) : (empty_1()))))))))), delay(() => append(singleton_1(createElement(AdditionalReminderWidget, {
                        AdditionlReminders: defaultArg(props.State.Dto.AdditionalReminders, empty()),
                        ComponentState: formState,
                        SetInterval: (tupledArg_2) => {
                            props.Dispatch(new EditReminder_Msg(1, [tupledArg_2[0], tupledArg_2[1], tupledArg_2[2]]));
                        },
                        SetName: (tupledArg_1) => {
                            props.Dispatch(new EditReminder_Msg(0, [tupledArg_1[0], tupledArg_1[1]]));
                        },
                    })), delay(() => singleton_1(createElement(TextArea, {
                        ComponentState: formState,
                        Label: t("general.description"),
                        OnChange: (value_11) => {
                            props.Dispatch(new EditReminder_Msg(6, [value_11]));
                        },
                        TestId: "tool-add-reminder-description-test-id",
                        Value: props.State.Dto.Description,
                    }))))));
                }));
            }))));
        }));
    })))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function EditReminder_OpenDialog(props) {
    let Title;
    const matchValue = props.Reminder;
    if (matchValue != null) {
        const reminder = matchValue;
        const t = useTranslation()[0];
        let patternInput_1;
        const init = EditReminder_init({
            OnClose: props.OnClose,
            Reminder: reminder,
            SuccessCallback: props.SuccessCallback,
            ToolId: props.ToolId,
        });
        patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, EditReminder_update, (_arg, _arg_1) => {
        }), undefined, [props.IsOpen]);
        const state_1 = patternInput_1[0];
        const dispatch = patternInput_1[1];
        return createElement(Dialog, (Title = t("tool.create_reminder"), {
            Body: singleton(createElement(EditReminder_DialogBody, {
                Dispatch: dispatch,
                ReminderSuggestions: state_1.ReminderNameSuggestions,
                State: state_1,
                SuccessCallback: props.SuccessCallback,
            })),
            Controls: toList(delay(() => {
                let matchValue_1, elems;
                return append((matchValue_1 = props.Reminder, (matchValue_1 != null) ? (matchValue_1.IsConfirmed ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["mr-auto"])], (elems = [createElement(TextButton, {
                    ComponentState: "enabled",
                    Label: t("general.mark_as_not_done"),
                    OnClick: () => {
                        dispatch(new EditReminder_Msg(11, []));
                    },
                    TestId: "mark-as-not-done-reminder-dialog-test-id",
                    Variant: "default",
                })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty_1()) : (empty_1())), delay(() => append(singleton_1(createElement(TextButton, {
                    ComponentState: "enabled",
                    Label: t("general.cancel"),
                    OnClick: props.OnClose,
                    TestId: "close-reminder-dialog-test-id",
                    Variant: "blueButton",
                })), delay(() => singleton_1(createElement(TextButton, {
                    ComponentState: state_1.IsLoading ? "disabled" : "enabled",
                    Label: t("general.save"),
                    OnClick: () => {
                        dispatch(new EditReminder_Msg(9, []));
                    },
                    TestId: "save-reminder-dialog-test-id",
                    Variant: "blueButton",
                }))))));
            })),
            IsOpen: props.IsOpen,
            OnClose: props.OnClose,
            Title: Title,
        }));
    }
    else {
        return defaultOf();
    }
}

class ConfirmDialog_Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetLastInspectionDate", "SetDate", "SetNextInspectionDate", "SetCreateNewDate", "SetConfirmationComment", "ConfirmToolReminder", "ToolReminderConfirmed", "FetchError"];
    }
}

function ConfirmDialog_Msg_$reflection() {
    return union_type("Tools.AddReminderDialog.ConfirmDialog.Msg", [], ConfirmDialog_Msg, () => [[["Item", bool_type]], [["Item", option_type(class_type("System.DateTime"))]], [["Item", option_type(class_type("System.DateTime"))]], [["Item", bool_type]], [["Item", string_type]], [], [["Item", PostResponse$1_$reflection(ToolReminderUpdateResult_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

function ConfirmDialog_confirmReminderCmd(toolId, dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(ToolReminderUpdateResult_$reflection())));
    })))), [`/api/tools/${unwrapToolId(toolId)}/reminder/confirm`, toString(0, Auto_generateBoxedEncoder_437914C6(ToolConfirmReminderDto_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new ConfirmDialog_Msg(6, [Item])), (Item_1) => (new ConfirmDialog_Msg(7, [Item_1])));
}

export class ConfirmDialog_State extends Record {
    constructor(ToolId, IsLoading, SuccessCallback, Dto, OnClose, IsNormalReminder, IsTitleInvalid, Reminder, NextInspectionDate) {
        super();
        this.ToolId = ToolId;
        this.IsLoading = IsLoading;
        this.SuccessCallback = SuccessCallback;
        this.Dto = Dto;
        this.OnClose = OnClose;
        this.IsNormalReminder = IsNormalReminder;
        this.IsTitleInvalid = IsTitleInvalid;
        this.Reminder = Reminder;
        this.NextInspectionDate = NextInspectionDate;
    }
}

export function ConfirmDialog_State_$reflection() {
    return record_type("Tools.AddReminderDialog.ConfirmDialog.State", [], ConfirmDialog_State, () => [["ToolId", ToolId_$reflection()], ["IsLoading", bool_type], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["Dto", ToolConfirmReminderDto_$reflection()], ["OnClose", lambda_type(unit_type, unit_type)], ["IsNormalReminder", bool_type], ["IsTitleInvalid", bool_type], ["Reminder", ReminderDateDto_$reflection()], ["NextInspectionDate", option_type(class_type("System.DateTime"))]]);
}

function ConfirmDialog_init(props) {
    let matchValue_1, customInterval, matchValue_2, IsNormalReminder;
    let nextInspectionDate;
    const now = props.Reminder.Date;
    const matchValue = props.Reminder.Interval;
    if (matchValue == null) {
        nextInspectionDate = undefined;
    }
    else {
        const interval = matchValue;
        nextInspectionDate = ((interval.tag === 1) ? addMonths(now, 1) : ((interval.tag === 2) ? addMonths(now, 3) : ((interval.tag === 3) ? addMonths(now, 6) : ((interval.tag === 4) ? addYears(now, 1) : ((interval.tag === 5) ? addYears(now, 2) : ((interval.tag === 6) ? ((matchValue_1 = props.Reminder.CustomInterval, (matchValue_1 == null) ? addDays(now, 0) : ((customInterval = matchValue_1, (matchValue_2 = customInterval.Interval, (matchValue_2 === "month") ? addMonths(now, customInterval.Value) : ((matchValue_2 === "week") ? addDays(now, customInterval.Value * 7) : ((matchValue_2 === "year") ? addYears(now, customInterval.Value) : addDays(now, customInterval.Value)))))))) : addDays(now, 7)))))));
    }
    return [(IsNormalReminder = (props.Reminder.Interval == null), new ConfirmDialog_State(props.ToolId, false, props.SuccessCallback, new ToolConfirmReminderDto(!props.Reminder.IsConfirmed, props.Reminder.Id, props.Reminder.Interval != null, false, undefined, map_1(toISOString, nextInspectionDate), ""), props.OnClose, IsNormalReminder, false, props.Reminder, nextInspectionDate)), Cmd_none()];
}

function ConfirmDialog_update(msg, state) {
    let bind$0040, bind$0040_1, bind$0040_2, bind$0040_3, bind$0040_4;
    switch (msg.tag) {
        case 0:
            return [new ConfirmDialog_State(state.ToolId, state.IsLoading, state.SuccessCallback, (bind$0040 = state.Dto, new ToolConfirmReminderDto(bind$0040.IsConfirmed, bind$0040.ReminderId, bind$0040.CreateNewDate, msg.fields[0], bind$0040.LastInspectionDate, bind$0040.NextInspectionDate, bind$0040.ConfirmationComment)), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.Reminder, state.NextInspectionDate), Cmd_none()];
        case 1: {
            const value_2 = msg.fields[0];
            if (value_2 == null) {
                return [state, Cmd_none()];
            }
            else {
                return [new ConfirmDialog_State(state.ToolId, state.IsLoading, state.SuccessCallback, (bind$0040_1 = state.Dto, new ToolConfirmReminderDto(bind$0040_1.IsConfirmed, bind$0040_1.ReminderId, bind$0040_1.CreateNewDate, bind$0040_1.SetLastInspectionDate, add(value_2, timeOfDay(now_1())), bind$0040_1.NextInspectionDate, bind$0040_1.ConfirmationComment)), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.Reminder, state.NextInspectionDate), Cmd_none()];
            }
        }
        case 3:
            return [new ConfirmDialog_State(state.ToolId, state.IsLoading, state.SuccessCallback, (bind$0040_2 = state.Dto, new ToolConfirmReminderDto(bind$0040_2.IsConfirmed, bind$0040_2.ReminderId, msg.fields[0], bind$0040_2.SetLastInspectionDate, bind$0040_2.LastInspectionDate, bind$0040_2.NextInspectionDate, bind$0040_2.ConfirmationComment)), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.Reminder, state.NextInspectionDate), Cmd_none()];
        case 4:
            return [new ConfirmDialog_State(state.ToolId, state.IsLoading, state.SuccessCallback, (bind$0040_3 = state.Dto, new ToolConfirmReminderDto(bind$0040_3.IsConfirmed, bind$0040_3.ReminderId, bind$0040_3.CreateNewDate, bind$0040_3.SetLastInspectionDate, bind$0040_3.LastInspectionDate, bind$0040_3.NextInspectionDate, msg.fields[0])), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.Reminder, state.NextInspectionDate), Cmd_none()];
        case 5:
            return [new ConfirmDialog_State(state.ToolId, true, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.Reminder, state.NextInspectionDate), ConfirmDialog_confirmReminderCmd(state.ToolId, (bind$0040_4 = state.Dto, new ToolConfirmReminderDto(bind$0040_4.IsConfirmed, bind$0040_4.ReminderId, bind$0040_4.CreateNewDate, bind$0040_4.SetLastInspectionDate, bind$0040_4.LastInspectionDate, state.Dto.CreateNewDate ? map_1(toISOString, state.NextInspectionDate) : undefined, bind$0040_4.ConfirmationComment)))];
        case 6:
            return [new ConfirmDialog_State(state.ToolId, false, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.Reminder, state.NextInspectionDate), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                state.SuccessCallback();
            }), Cmd_ofEffect((_arg_1) => {
                state.OnClose();
            }), Cmd_successToast("tool.dialog_mark_appointment_as_done_success")]))];
        case 7:
            return [new ConfirmDialog_State(state.ToolId, false, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.Reminder, state.NextInspectionDate), Cmd_none()];
        default:
            return [new ConfirmDialog_State(state.ToolId, state.IsLoading, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.Reminder, msg.fields[0]), Cmd_none()];
    }
}

function ConfirmDialog_DialogBody(props) {
    let elems, elems_2;
    const t = useTranslation()[0];
    const isCreateNextReminderCheckboxVisible = !props.State.Reminder.IsConfirmed && (props.State.Reminder.Interval != null);
    const labelText = props.State.Reminder.IsConfirmed ? t("tool.dialog_mark_appointment_as_not_done") : t("tool.dialog_mark_appointment_as_done");
    const formState = props.State.IsLoading ? "disabled" : "enabled";
    if (props.State.Reminder.IsConfirmed) {
        return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [createElement(InformationAlert, {
            Label: t("reminder_dialog.mark_as_not_done_information"),
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
    }
    else {
        return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems_2 = toList(delay(() => append(singleton_1(createElement(TextValue, {
            FontWeight: "normal",
            TestId: "",
            Text: labelText,
        })), delay(() => append(isCreateNextReminderCheckboxVisible ? append(singleton_1(Checkbox({
            ComponentState: formState,
            IsChecked: props.State.Dto.CreateNewDate,
            Label: t("reminder_dialog.normal_reminder"),
            OnCheck: (value_4) => {
                props.Dispatch(new ConfirmDialog_Msg(3, [value_4]));
            },
            TestId: "",
        })), delay(() => append(singleton_1(createElement(InformationAlert, {
            Label: t("reminder_dialog.create_new_reminder_info"),
        })), delay(() => (props.State.Dto.CreateNewDate ? singleton_1(createElement(DatePicker, {
            ComponentState: formState,
            IsClearable: true,
            Label: t("tool.next_inspection"),
            OnChange: (newDate) => {
                props.Dispatch(new ConfirmDialog_Msg(2, [newDate]));
            },
            TestId: "tool-add-reminder-dialog-next-date-test-id",
            Value: unwrap(props.State.NextInspectionDate),
        })) : empty_1()))))) : singleton_1(defaultOf()), delay(() => (!props.State.Reminder.IsConfirmed ? append(singleton_1(Checkbox({
            ComponentState: formState,
            IsChecked: props.State.Dto.SetLastInspectionDate,
            Label: t("reminder_dialog.set_last_check_field"),
            OnCheck: (value_6) => {
                props.Dispatch(new ConfirmDialog_Msg(0, [value_6]));
            },
            TestId: "",
        })), delay(() => append(singleton_1(createElement(InformationAlert, {
            Label: t("reminder_dialog.set_last_check_field_info"),
        })), delay(() => {
            let elems_1;
            return append(props.State.Dto.SetLastInspectionDate ? singleton_1(createElement("div", createObj(singleton((elems_1 = [createElement(DatePicker, {
                ComponentState: formState,
                IsClearable: true,
                Label: t("reminder_dialog.check_date"),
                OnChange: (newDate_1) => {
                    props.Dispatch(new ConfirmDialog_Msg(1, [newDate_1]));
                },
                TestId: "tool-add-reminder-dialog-check-date-test-id",
                Value: unwrap(props.State.Dto.LastInspectionDate),
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))]))))) : singleton_1(defaultOf()), delay(() => singleton_1(createElement(TextArea, {
                ComponentState: formState,
                Label: t("general.comment"),
                OnChange: (value_8) => {
                    props.Dispatch(new ConfirmDialog_Msg(4, [value_8]));
                },
                TestId: "tool-add-reminder-dialog-comment-test-id",
                Value: props.State.Dto.ConfirmationComment,
            }))));
        })))) : singleton_1(defaultOf())))))))), ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
    }
}

export function ConfirmDialog_ConfirmReminderDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init = ConfirmDialog_init({
        OnClose: props.OnClose,
        Reminder: props.Reminder,
        SuccessCallback: props.SuccessCallback,
        ToolId: props.ToolId,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, ConfirmDialog_update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    return createElement(Dialog, (Title = t("general.mark_as_done"), {
        Body: singleton(createElement(ConfirmDialog_DialogBody, {
            Dispatch: dispatch,
            State: state_1,
        })),
        Controls: toList(delay(() => append(singleton_1(createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-confirm-reminder-dialog-test-id",
            Variant: "blueButton",
        })), delay(() => (!state_1.Reminder.IsConfirmed ? singleton_1(createElement(TextButton, {
            ComponentState: state_1.IsLoading ? "disabled" : "enabled",
            Label: t("general.mark_as_done"),
            OnClick: () => {
                dispatch(new ConfirmDialog_Msg(5, []));
            },
            TestId: "save-confirm-dialog-test-id",
            Variant: "blueButton",
        })) : empty_1()))))),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

class DeleteReminder_Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DeleteReminder", "ReminderDeleted", "FetchError"];
    }
}

function DeleteReminder_Msg_$reflection() {
    return union_type("Tools.AddReminderDialog.DeleteReminder.Msg", [], DeleteReminder_Msg, () => [[], [["Item", PostResponse$1_$reflection(EmptyResponse_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

function DeleteReminder_deleteReminderCmd(toolId, reminderId) {
    return Cmd_OfPromise_either((url_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_1), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(EmptyResponse_$reflection())));
    })))), `/api/tools/${toolId.fields[0]}/reminder/${reminderId.fields[0]}`, (Item) => (new DeleteReminder_Msg(1, [Item])), (Item_1) => (new DeleteReminder_Msg(2, [Item_1])));
}

export class DeleteReminder_State extends Record {
    constructor(ToolId, IsLoading, SuccessCallback, OnClose, ReminderId) {
        super();
        this.ToolId = ToolId;
        this.IsLoading = IsLoading;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.ReminderId = ReminderId;
    }
}

export function DeleteReminder_State_$reflection() {
    return record_type("Tools.AddReminderDialog.DeleteReminder.State", [], DeleteReminder_State, () => [["ToolId", ToolId_$reflection()], ["IsLoading", bool_type], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["ReminderId", string_type]]);
}

function DeleteReminder_init(props) {
    return [new DeleteReminder_State(props.ToolId, false, props.SuccessCallback, props.OnClose, props.Reminder.Id), Cmd_none()];
}

function DeleteReminder_update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new DeleteReminder_State(state.ToolId, false, state.SuccessCallback, state.OnClose, state.ReminderId), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                state.SuccessCallback();
            }), Cmd_ofEffect((_arg_1) => {
                state.OnClose();
            }), Cmd_successToast("tool.dialog_remove_reminder_success")]))];
        case 2:
            return [new DeleteReminder_State(state.ToolId, false, state.SuccessCallback, state.OnClose, state.ReminderId), Cmd_none()];
        default:
            return [new DeleteReminder_State(state.ToolId, true, state.SuccessCallback, state.OnClose, state.ReminderId), DeleteReminder_deleteReminderCmd(state.ToolId, new ReminderId_1(parse(state.ReminderId)))];
    }
}

export function DeleteReminder_OpenDialog(props) {
    let Title;
    const matchValue = props.Reminder;
    if (matchValue != null) {
        const reminder = matchValue;
        const t = useTranslation()[0];
        let patternInput_1;
        const init = DeleteReminder_init({
            OnClose: props.OnClose,
            Reminder: reminder,
            SuccessCallback: props.SuccessCallback,
            ToolId: props.ToolId,
        });
        patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, DeleteReminder_update, (_arg, _arg_1) => {
        }), undefined, [props.IsOpen]);
        return createElement(ConfirmationDialog, (Title = t("general.delete"), {
            Description: t("tool.dialog_remove_reminder_query"),
            IsOpen: props.IsOpen,
            OnClose: props.OnClose,
            OnConfirm: patternInput_1[0].IsLoading ? (() => {
            }) : (() => {
                patternInput_1[1](new DeleteReminder_Msg(0, []));
            }),
            Title: Title,
        }));
    }
    else {
        return defaultOf();
    }
}

