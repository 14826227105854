import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { ColumnDefinition$1, CellContent, Table } from "../../Widgets/Table/Table.js";
import { empty, filter, ofArray, toArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { singleton, append, delay, toArray as toArray_1 } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { Helper_unitToTranslationKey } from "../../Shared/Consumable.js";
import { Table_customPropertyValueDtoColumnDefinitions } from "../../Widgets/CustomProperties.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";

export function ReportView(props) {
    let elems_2, elems_1, elems, TableRows;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", "flex-grow-1 d-flex flex-column"], (elems_2 = [createElement("div", createObj(ofArray([["id", "consumable-assignment-report-id"], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems = [createElement(Table, (TableRows = toArray(props.Report), {
        ColumnDefinitions: toArray_1(delay(() => append(singleton(new ColumnDefinition$1("timestamp", "", t("general.date"), (row) => (new CellContent(6, [row.Timestamp])), "dateRange", "date")), delay(() => append(singleton(new ColumnDefinition$1("displayName", "", t("general.name"), (row_1) => (new CellContent(2, [row_1.ConsumableInformation.Name, () => {
            RouterModule_nav(ofArray(toArray(row_1.ConsumableInformation.OnClickPath)), 1, 1);
        }])), "text", "button")), delay(() => append(singleton(new ColumnDefinition$1("additionalName", "", t("consumable.additional_name"), (row_2) => (new CellContent(0, [row_2.ConsumableInformation.AdditionalName])), "text", "text")), delay(() => append(singleton(new ColumnDefinition$1("storage", "", t("general.storage"), (row_3) => (new CellContent(0, [row_3.StorageName])), "select", "text")), delay(() => append(singleton(new ColumnDefinition$1("commission", "", t("general.commission"), (row_4) => (new CellContent(0, [row_4.CommissionNumber])), "text", "text")), delay(() => append(singleton(new ColumnDefinition$1("receiver", "", t("general.receiver"), (row_5) => (new CellContent(2, [row_5.ReceiverInformation.Name, () => {
            RouterModule_nav(ofArray(toArray(row_5.ReceiverInformation.OnClickPath)), 1, 1);
        }])), "select", "text")), delay(() => append(singleton(new ColumnDefinition$1("quantity", "", t("general.quantity"), (row_6) => (new CellContent(0, [row_6.Quantity.toString()])), "text", "text")), delay(() => append(singleton(new ColumnDefinition$1("unit", "", t("general.unit"), (row_7) => (new CellContent(0, [t(Helper_unitToTranslationKey(row_7.ConsumableInformation.Unit))])), "select", "text")), delay(() => Table_customPropertyValueDtoColumnDefinitions(filter((propertyConfiguration) => {
            const matchValue = propertyConfiguration.Visibility;
            switch (matchValue.tag) {
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                    return false;
                default:
                    return true;
            }
        }, props.PropertyConfigurations), t("tool.custom_properties"), (dto) => dto.CustomProperties, t))))))))))))))))))),
        Controls: empty(),
        Options: {
            BorderStyle: "full",
            CSVDownload: true,
            ConfigurableColumns: false,
            DefaultSortColumn: ["timestamp", "desc"],
            GlobalSearch: true,
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
}

