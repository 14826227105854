import { Record, Union } from "../../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, union_type, class_type, list_type, anonRecord_type, bool_type, string_type, option_type, int32_type } from "../../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Helper_receiverTypeNameTranslation, Requests_UserDto_$reflection, ReceiverTypeName, Receiver_$reflection } from "../../../Shared/User.js";
import { Storage_$reflection } from "../../../Shared/Storage.js";
import { FormState__getValidation, UserId as UserId_1, FormState__startLoading, AssignToolWithQuantityWithNotificationDto, ToolId, FormState__setValidationResponse_5219762A, RequestedValue$1, FormState_get_empty, AssignToolWithQuantityWithoutNotificationDto, RequestedValue$1_$reflection, FormState_$reflection, AssignToolWithQuantityWithoutNotificationDto_$reflection, ClientApiResponse$1_$reflection } from "../../../Shared/Shared.js";
import { ToolWithQuantityDetailDto_$reflection } from "../../../Shared/Tool.js";
import { Cmd_ofEffect, Cmd_none, Cmd_batch } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { Async_fetchWithDecoder, addPrefix, fetchWithDecoder } from "../../../Communication.js";
import { Types_RequestProperties } from "../../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { sortBy, toArray, isEmpty, sum, tryFind, contains, map, filter, find, ofArray, singleton } from "../../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Cmd_successToast, Cmd_errorToast } from "../../../Components/Toast.js";
import { doRangesOverlap, unwrapStorageId } from "../../../Shared/Helper.js";
import { safeHash, comparePrimitives, createObj, stringHash, equals } from "../../../fable_modules/fable-library-js.4.19.2/Util.js";
import { assignCmd, assignWithNotificationCmd } from "../../../Requests/ToolWithQuantity.js";
import { parse } from "../../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useFeliz_React__React_useDeferred_Static_Z241A641 } from "../../../fable_modules/Feliz.UseDeferred.2.0.0/UseDeferred.fs.js";
import { unwrapUserId } from "../../../Shared/Helper.js";
import { HttpMethod } from "../../../fable_modules/Fable.SimpleHttp.3.6.0/Types.fs.js";
import { singleton as singleton_1 } from "../../../fable_modules/fable-library-js.4.19.2/AsyncBuilder.js";
import { spinner } from "../../../SharedComponents/Spinners.js";
import { unwrap, map as map_1 } from "../../../fable_modules/fable-library-js.4.19.2/Option.js";
import { addDays, today } from "../../../fable_modules/fable-library-js.4.19.2/Date.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton as singleton_2, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { ErrorAlert, InformationAlert, WarningAlert } from "../../../Components/Alert.js";
import { GroupedSelect, Select } from "../../../Components/Select.js";
import { List_groupBy } from "../../../fable_modules/fable-library-js.4.19.2/Seq2.js";
import { TextArea } from "../../../Components/TextArea.js";
import { NumberInput } from "../../../Components/Input.js";
import { numberInputIntMaxValue } from "../../../Common.js";
import { Checkbox } from "../../../Components/Checkbox.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../../Components/Dialog.js";
import { Skeleton } from "../../../Components/Skeleton.js";
import { TextButton } from "../../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetQuantity", "SetComment", "SetWithNotification", "SetWithSignature", "SetSelectedUser", "SetSelectedStorage", "AssignedToolResponse", "OnAssignTool", "ReceiversFetched", "StoragesFetched", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Tools.AssignToolWithQuantityDialog.Msg", [], Msg, () => [[["Item", option_type(int32_type)]], [["Item", string_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", option_type(anonRecord_type(["label", string_type], ["value", Receiver_$reflection()]))]], [["Item", option_type(anonRecord_type(["label", string_type], ["value", Storage_$reflection()]))]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [], [["Item", list_type(Receiver_$reflection())]], [["Item", list_type(Storage_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(Quantity, ToolWithQuantity, Dto, FormState, SuccessCallback, OnClose, SelectedUser, SelectedStorage, IsStockInvalid, WithNotification, WithSignature, Receivers, Storages) {
        super();
        this.Quantity = Quantity;
        this.ToolWithQuantity = ToolWithQuantity;
        this.Dto = Dto;
        this.FormState = FormState;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.SelectedUser = SelectedUser;
        this.SelectedStorage = SelectedStorage;
        this.IsStockInvalid = IsStockInvalid;
        this.WithNotification = WithNotification;
        this.WithSignature = WithSignature;
        this.Receivers = Receivers;
        this.Storages = Storages;
    }
}

function State_$reflection() {
    return record_type("Tools.AssignToolWithQuantityDialog.State", [], State, () => [["Quantity", option_type(int32_type)], ["ToolWithQuantity", ToolWithQuantityDetailDto_$reflection()], ["Dto", AssignToolWithQuantityWithoutNotificationDto_$reflection()], ["FormState", FormState_$reflection()], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["SelectedUser", option_type(anonRecord_type(["label", string_type], ["value", Receiver_$reflection()]))], ["SelectedStorage", option_type(anonRecord_type(["label", string_type], ["value", Storage_$reflection()]))], ["IsStockInvalid", option_type(int32_type)], ["WithNotification", bool_type], ["WithSignature", bool_type], ["Receivers", RequestedValue$1_$reflection(list_type(Receiver_$reflection()))], ["Storages", RequestedValue$1_$reflection(list_type(Storage_$reflection()))]]);
}

function init(toolWithQuantity, userId, successCallback, onClose) {
    return [new State(undefined, toolWithQuantity, new AssignToolWithQuantityWithoutNotificationDto("", undefined, "", 1, "", undefined), FormState_get_empty(), successCallback, onClose, undefined, undefined, undefined, false, false, new RequestedValue$1(0, []), new RequestedValue$1(0, [])), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Receiver_$reflection())));
    })))), "/api/receivers/active", (Item) => (new Msg(8, [Item])), (Item_1) => (new Msg(10, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Storage_$reflection())));
    })))), "/api/storages/access", (Item_2) => (new Msg(9, [Item_2])), (Item_3) => (new Msg(10, [Item_3])))]))];
}

function update(msg, state) {
    let bind$0040, bind$0040_1;
    switch (msg.tag) {
        case 0:
            return [new State(msg.fields[0], state.ToolWithQuantity, state.Dto, state.FormState, state.SuccessCallback, state.OnClose, state.SelectedUser, state.SelectedStorage, state.IsStockInvalid, state.WithNotification, state.WithSignature, state.Receivers, state.Storages), Cmd_none()];
        case 5:
            return [new State(state.Quantity, state.ToolWithQuantity, state.Dto, state.FormState, state.SuccessCallback, state.OnClose, state.SelectedUser, msg.fields[0], state.IsStockInvalid, state.WithNotification, state.WithSignature, state.Receivers, state.Storages), Cmd_none()];
        case 1:
            return [new State(state.Quantity, state.ToolWithQuantity, (bind$0040 = state.Dto, new AssignToolWithQuantityWithoutNotificationDto(bind$0040.ReceiverId, bind$0040.SenderId, bind$0040.StorageId, bind$0040.Quantity, msg.fields[0], bind$0040.SignatureUrl)), state.FormState, state.SuccessCallback, state.OnClose, state.SelectedUser, state.SelectedStorage, state.IsStockInvalid, state.WithNotification, state.WithSignature, state.Receivers, state.Storages), Cmd_none()];
        case 4:
            return [new State(state.Quantity, state.ToolWithQuantity, state.Dto, state.FormState, state.SuccessCallback, state.OnClose, msg.fields[0], state.SelectedStorage, state.IsStockInvalid, state.WithNotification, state.WithSignature, state.Receivers, state.Storages), Cmd_none()];
        case 2: {
            const withNotification = msg.fields[0];
            if (!withNotification) {
                return [new State(state.Quantity, state.ToolWithQuantity, state.Dto, state.FormState, state.SuccessCallback, state.OnClose, state.SelectedUser, state.SelectedStorage, state.IsStockInvalid, withNotification, false, state.Receivers, state.Storages), Cmd_none()];
            }
            else {
                return [new State(state.Quantity, state.ToolWithQuantity, state.Dto, state.FormState, state.SuccessCallback, state.OnClose, state.SelectedUser, state.SelectedStorage, state.IsStockInvalid, withNotification, state.WithSignature, state.Receivers, state.Storages), Cmd_none()];
            }
        }
        case 3: {
            const withSignature = msg.fields[0];
            if (withSignature) {
                return [new State(state.Quantity, state.ToolWithQuantity, state.Dto, state.FormState, state.SuccessCallback, state.OnClose, state.SelectedUser, state.SelectedStorage, state.IsStockInvalid, true, withSignature, state.Receivers, state.Storages), Cmd_none()];
            }
            else {
                return [new State(state.Quantity, state.ToolWithQuantity, state.Dto, state.FormState, state.SuccessCallback, state.OnClose, state.SelectedUser, state.SelectedStorage, state.IsStockInvalid, state.WithNotification, withSignature, state.Receivers, state.Storages), Cmd_none()];
            }
        }
        case 6: {
            const response_1 = msg.fields[0];
            if (response_1.tag === 1) {
                return [new State(state.Quantity, state.ToolWithQuantity, state.Dto, FormState__setValidationResponse_5219762A(state.FormState, response_1.fields[0]), state.SuccessCallback, state.OnClose, state.SelectedUser, state.SelectedStorage, state.IsStockInvalid, state.WithNotification, state.WithSignature, state.Receivers, state.Storages), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.Quantity, state.ToolWithQuantity, state.Dto, FormState_get_empty(), state.SuccessCallback, state.OnClose, state.SelectedUser, state.SelectedStorage, state.IsStockInvalid, state.WithNotification, state.WithSignature, state.Receivers, state.Storages), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_successToast("general.saved_successfully"), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                })]))];
            }
        }
        case 7: {
            const matchValue = state.SelectedUser;
            const matchValue_1 = state.SelectedStorage;
            const matchValue_2 = state.Quantity;
            let matchResult, quantity, receiver, storage;
            if (matchValue == null) {
                matchResult = 1;
            }
            else if (matchValue_1 == null) {
                matchResult = 1;
            }
            else if (matchValue_2 == null) {
                matchResult = 1;
            }
            else {
                matchResult = 0;
                quantity = matchValue_2;
                receiver = matchValue;
                storage = matchValue_1;
            }
            switch (matchResult) {
                case 0: {
                    const sps_2 = find((sps) => (sps.StorageId === unwrapStorageId(storage.value.Id)), state.ToolWithQuantity.StockPerStorage).Stock | 0;
                    if (sps_2 >= quantity) {
                        const cmd = (state.WithNotification && (equals(receiver.value.ReceiverType, new ReceiverTypeName(0, [])) ? true : (receiver.value.Email != null))) ? assignWithNotificationCmd(new ToolId(parse(state.ToolWithQuantity.Id)), new AssignToolWithQuantityWithNotificationDto(receiver.value.Id, unwrapStorageId(storage.value.Id), quantity, state.Dto.Comment, state.WithSignature, "storageToUser"), (Item_1) => (new Msg(6, [Item_1])), (Item_2) => (new Msg(10, [Item_2]))) : assignCmd(new ToolId(parse(state.ToolWithQuantity.Id)), (bind$0040_1 = state.Dto, new AssignToolWithQuantityWithoutNotificationDto(receiver.value.Id, bind$0040_1.SenderId, unwrapStorageId(storage.value.Id), quantity, bind$0040_1.Comment, bind$0040_1.SignatureUrl)), (Item_4) => (new Msg(6, [Item_4])), (Item_5) => (new Msg(10, [Item_5])));
                        return [new State(state.Quantity, state.ToolWithQuantity, state.Dto, FormState__startLoading(state.FormState), state.SuccessCallback, state.OnClose, state.SelectedUser, state.SelectedStorage, undefined, state.WithNotification, state.WithSignature, state.Receivers, state.Storages), cmd];
                    }
                    else {
                        return [new State(state.Quantity, state.ToolWithQuantity, state.Dto, state.FormState, state.SuccessCallback, state.OnClose, state.SelectedUser, state.SelectedStorage, sps_2, state.WithNotification, state.WithSignature, state.Receivers, state.Storages), Cmd_none()];
                    }
                }
                default:
                    return [state, Cmd_none()];
            }
        }
        case 9:
            return [new State(state.Quantity, state.ToolWithQuantity, state.Dto, state.FormState, state.SuccessCallback, state.OnClose, state.SelectedUser, state.SelectedStorage, state.IsStockInvalid, state.WithNotification, state.WithSignature, state.Receivers, new RequestedValue$1(1, [msg.fields[0]])), Cmd_none()];
        case 10:
            return [state, Cmd_none()];
        default:
            return [new State(state.Quantity, state.ToolWithQuantity, state.Dto, state.FormState, state.SuccessCallback, state.OnClose, state.SelectedUser, state.SelectedStorage, state.IsStockInvalid, state.WithNotification, state.WithSignature, new RequestedValue$1(1, [msg.fields[0]]), state.Storages), Cmd_none()];
    }
}

function targetStorages(user, props) {
    return filter((s) => {
        const source = map((sps) => sps.StorageId, props.Tool.StockPerStorage);
        return contains(unwrapStorageId(s.Id), source, {
            Equals: (x, y) => (x === y),
            GetHashCode: stringHash,
        });
    }, props.Storages);
}

function DialogBody(props) {
    let quantity_1, stock_2, quantity, stock_1, elems, elems_3;
    const patternInput = useTranslation();
    const t = patternInput[0];
    const data = useFeliz_React__React_useDeferred_Static_Z241A641(Async_fetchWithDecoder(0, addPrefix(`/api/receivers/users/${unwrapUserId(new UserId_1(props.UserId))}/details`), "", new HttpMethod(0, []), "application/json", (response) => singleton_1.Delay(() => {
        let resp;
        const matchValue = response.content;
        switch (matchValue.tag) {
            case 0: {
                throw new Error("not implemented - text response");
                break;
            }
            case 1: {
                throw new Error("not implemented - blob response");
                break;
            }
            case 3: {
                resp = matchValue.fields[0];
                break;
            }
            default:
                resp = (new Uint8Array(matchValue.fields[0]));
        }
        return singleton_1.Return(Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(resp), Requests_UserDto_$reflection()));
    })), []);
    switch (data.tag) {
        case 1:
        case 0:
            return spinner;
        case 2: {
            const targetStorages_1 = targetStorages(data.fields[0], {
                Storages: props.Storages,
                Tool: props.Tool,
                UserId: props.UserId,
            });
            const storageOptions = map((storage) => ({
                label: storage.Name,
                value: storage,
            }), targetStorages_1);
            let isEnoughStock;
            const matchValue_1 = props.SelectedStorage;
            if (matchValue_1 == null) {
                isEnoughStock = true;
            }
            else {
                const storage_1 = matchValue_1;
                const stock = map_1((stockPerStorage_1) => stockPerStorage_1.Stock, tryFind((stockPerStorage) => (stockPerStorage.StorageId === unwrapStorageId(storage_1.value.Id)), props.Tool.StockPerStorage));
                const reservedStock = sum(map((reservation_1) => reservation_1.Quantity, filter((reservation) => doRangesOverlap(today(), addDays(today(), 7), reservation.StartDate, reservation.EndDate), props.Tool.Reservations)), {
                    GetZero: () => 0,
                    Add: (x_1, y) => (x_1 + y),
                }) | 0;
                const matchValue_2 = props.Quantity;
                isEnoughStock = ((stock == null) ? ((matchValue_2 == null) ? true : ((quantity_1 = (matchValue_2 | 0), (reservedStock - quantity_1) >= 0))) : ((matchValue_2 == null) ? ((stock_2 = (stock | 0), (stock_2 - reservedStock) >= 0)) : ((quantity = (matchValue_2 | 0), (stock_1 = (stock | 0), ((stock_1 - reservedStock) - quantity) >= 0)))));
            }
            if (isEmpty(targetStorages_1)) {
                return createElement("div", createObj(singleton((elems = [createElement("strong", {
                    children: t("tool.not_responsible_storage_msg"),
                }), createElement("p", {
                    children: t("tool.not_responsible_storage_info_msg"),
                })], ["children", reactApi.Children.toArray(Array.from(elems))]))));
            }
            else {
                return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems_3 = toList(delay(() => {
                    let elems_1, arg_1;
                    return append(!isEnoughStock ? singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["max-w-[40rem]"])], (elems_1 = [createElement(WarningAlert, {
                        Label: (arg_1 = {
                            dateString: "reminder.Date.ToString(\"dd.MM.yyyy\")",
                            reminderName: "reminder.Title",
                        }, patternInput[1].t("tool.reservation_dialog.reservation_stock_to_low_warning", arg_1)),
                    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))) : empty(), delay(() => append(singleton_2(createElement(Select, {
                        ComponentState: props.ComponentState,
                        IsClearable: false,
                        Label: t("tool.choose_storage"),
                        NoOptionsMessage: "select.storage_no_options",
                        OnChange: (arg_2) => {
                            props.Dispatch(new Msg(5, [arg_2]));
                        },
                        Options: storageOptions,
                        PlaceholderKey: "select.storage_placeholder",
                        TestId: "choose-storage-select-test-id",
                        Value: unwrap(props.SelectedStorage),
                    })), delay(() => append(singleton_2(createElement(GroupedSelect, (($value) => { $value.key = $value.Key; return $value; })({
                        ComponentState: "enabled",
                        IsClearable: false,
                        Key: "select-user",
                        Label: t("tool.choose_receiver"),
                        NoOptionsMessage: "select.employee_no_options",
                        OnChange: (arg_3) => {
                            props.Dispatch(new Msg(4, [arg_3]));
                        },
                        Options: map((tupledArg) => {
                            const receiverType = tupledArg[0];
                            const receivers_1 = toArray(sortBy((receiver_2) => receiver_2.label.toLowerCase(), map((receiver_1) => {
                                const matchValue_5 = receiver_1.LocationName;
                                if (matchValue_5 != null) {
                                    const locationName = matchValue_5;
                                    if (equals(receiverType, new ReceiverTypeName(4, []))) {
                                        return {
                                            label: `${locationName} - ${receiver_1.Name}`,
                                            value: receiver_1,
                                        };
                                    }
                                    else {
                                        return {
                                            label: `${receiver_1.Name} - ${locationName}`,
                                            value: receiver_1,
                                        };
                                    }
                                }
                                else {
                                    return {
                                        label: `${receiver_1.Name}`,
                                        value: receiver_1,
                                    };
                                }
                            }, tupledArg[1]), {
                                Compare: comparePrimitives,
                            }));
                            return {
                                label: Helper_receiverTypeNameTranslation(t, receiverType),
                                options: receivers_1,
                            };
                        }, List_groupBy((receiver) => receiver.ReceiverType, filter((u) => {
                            if (props.WithNotification) {
                                const matchValue_4 = u.ReceiverType;
                                switch (matchValue_4.tag) {
                                    case 1:
                                    case 5:
                                    case 8:
                                    case 6:
                                    case 2:
                                    case 7:
                                    case 3:
                                    case 9:
                                    case 4:
                                        return u.Email != null;
                                    default:
                                        return true;
                                }
                            }
                            else {
                                return true;
                            }
                        }, props.Users), {
                            Equals: equals,
                            GetHashCode: safeHash,
                        })),
                        PlaceholderKey: "select.employee_placeholder",
                        Value: unwrap(props.SelectedReceiver),
                    }))), delay(() => append(singleton_2(createElement(TextArea, {
                        ComponentState: props.ComponentState,
                        Label: t("general.comment"),
                        OnChange: (arg_4) => {
                            props.Dispatch(new Msg(1, [arg_4]));
                        },
                        TestId: "toolwithquantity-assign-comment-test-id",
                        Value: props.Comment,
                    })), delay(() => {
                        let Label_3, Value_3;
                        return append(singleton_2(createElement(NumberInput, (Label_3 = t("general.quantity_3"), (Value_3 = map_1((value_9) => value_9, props.Quantity), {
                            ComponentState: props.ComponentState,
                            Label: unwrap(Label_3),
                            MaxValue: numberInputIntMaxValue,
                            OnChange: (arg_6) => {
                                props.Dispatch(new Msg(0, [map_1((value_10) => ~~value_10, arg_6)]));
                            },
                            TestId: "quantity-input-test-id",
                            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "quantity")),
                            Value: unwrap(Value_3),
                        })))), delay(() => {
                            let Label_4, matchValue_6, selectedUser;
                            return append(singleton_2(Checkbox((Label_4 = t("tool.dialog_assign_with_notification"), {
                                ComponentState: (matchValue_6 = props.SelectedReceiver, (matchValue_6 != null) ? ((selectedUser = matchValue_6, (props.ComponentState === "enabled") ? ((equals(selectedUser.value.ReceiverType, new ReceiverTypeName(0, [])) ? true : (selectedUser.value.Email != null)) ? "enabled" : "disabled") : props.ComponentState)) : props.ComponentState),
                                IsChecked: props.WithNotification,
                                Label: Label_4,
                                OnCheck: (arg_7) => {
                                    props.Dispatch(new Msg(2, [arg_7]));
                                },
                                TestId: "assign-tool-dialog-with-notification-test-id",
                            }))), delay(() => {
                                let elems_2;
                                return append(singleton_2(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_2 = [createElement(InformationAlert, {
                                    Label: t("tool.dialog_assign_with_notification_info"),
                                })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                                    let Label_5, matchValue_8, selectedUser_1;
                                    return append(singleton_2(Checkbox((Label_5 = t("tool.dialog_assign_with_signature"), {
                                        ComponentState: (matchValue_8 = props.SelectedReceiver, (matchValue_8 != null) ? ((selectedUser_1 = matchValue_8, (props.ComponentState === "enabled") ? ((equals(selectedUser_1.value.ReceiverType, new ReceiverTypeName(0, [])) ? true : (selectedUser_1.value.Email != null)) ? "enabled" : "disabled") : props.ComponentState)) : props.ComponentState),
                                        IsChecked: props.WithSignature,
                                        Label: Label_5,
                                        OnCheck: (arg_8) => {
                                            props.Dispatch(new Msg(3, [arg_8]));
                                        },
                                        TestId: "assign-tool-dialog-with-notification-test-id",
                                    }))), delay(() => {
                                        const matchValue_10 = FormState__getValidation(props.FormState, t, "global");
                                        if (matchValue_10 == null) {
                                            return empty();
                                        }
                                        else {
                                            return singleton_2(createElement(ErrorAlert, {
                                                Label: matchValue_10,
                                            }));
                                        }
                                    }));
                                }));
                            }));
                        }));
                    }))))))));
                })), ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
            }
        }
        default:
            return `error ${data.fields[0].message}`;
    }
}

export function AssignToolWithQuantityDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init(props.Tool, props.UserId, props.SuccessCallback, props.OnClose);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const componentState = state_1.FormState.IsLoading ? "disabled" : "enabled";
    return createElement(Dialog, (Title = t("tool.deal_out_tool"), {
        Body: toList(delay(() => {
            const matchValue = state_1.Receivers;
            const matchValue_1 = state_1.Storages;
            let matchResult, receivers, storages;
            if (matchValue.tag === 0) {
                if (matchValue_1.tag === 0) {
                    matchResult = 1;
                }
                else {
                    matchResult = 1;
                }
            }
            else if (matchValue_1.tag === 0) {
                matchResult = 1;
            }
            else {
                matchResult = 0;
                receivers = matchValue.fields[0];
                storages = matchValue_1.fields[0];
            }
            switch (matchResult) {
                case 0:
                    return singleton_2(createElement(DialogBody, {
                        Comment: state_1.Dto.Comment,
                        ComponentState: componentState,
                        Dispatch: dispatch,
                        FormState: state_1.FormState,
                        IsStockInvalid: unwrap(state_1.IsStockInvalid),
                        Quantity: unwrap(state_1.Quantity),
                        SelectedReceiver: unwrap(state_1.SelectedUser),
                        SelectedStorage: unwrap(state_1.SelectedStorage),
                        Storages: storages,
                        Tool: props.Tool,
                        UserId: props.UserId,
                        Users: receivers,
                        WithNotification: state_1.WithNotification,
                        WithSignature: state_1.WithSignature,
                    }));
                default:
                    return singleton_2(createElement(Skeleton, {
                        Variant: "normal",
                    }));
            }
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-assign-twq-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: componentState,
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new Msg(7, []));
            },
            TestId: "save-assign-twq-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

