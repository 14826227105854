import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.2/String.js";
import { TextValue } from "../../../Components/Text.js";
import { empty, singleton, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { ErrorAlert, InformationAlert } from "../../../Components/Alert.js";
import { Variant as Variant_1, debugActive } from "../../../Variant.js";
import { Checkbox } from "../../../Components/Checkbox.js";
import { Msg } from "./Types.js";
import { Input } from "../../../Components/Input.js";
import { unwrap } from "../../../fable_modules/fable-library-js.4.19.2/Option.js";
import { FormState__getValidation } from "../../../Shared/Shared.js";
import { Button } from "../../../Components/Button.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { ofArray } from "../../../fable_modules/fable-library-js.4.19.2/List.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { update as update_1, init as init_1 } from "./State.js";
import { defaultOf } from "../../../fable_modules/fable-library-js.4.19.2/Util.js";

function SSOConfiguration(props) {
    let elems_2, elems_1;
    const patternInput = useTranslation();
    const t = patternInput[0];
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-4"])], (elems_2 = [createElement(TextValue, {
        FontWeight: "bold",
        TestId: "",
        Text: t("settings.sso.sso_configuration"),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-3"])], (elems_1 = toList(delay(() => {
        let arg_1;
        return append(singleton(createElement(InformationAlert, {
            Label: (arg_1 = {
                uri: debugActive ? (`https://app.inventory-one.com/auth/realms/development/broker/${props.UserData.CustomerId}/endpoint`) : ((Variant_1.tag === 0) ? (`https://app.meinwerkzeugkoffer.de/auth/realms/mein-werkzeugkoffer/broker/${props.UserData.CustomerId}/endpoint`) : ((Variant_1.tag === 2) ? (`https://app.eagletrackmate.com/auth/realms/inventory-one/broker/${props.UserData.CustomerId}/endpoint`) : (`https://app.inventory-one.com/auth/realms/inventory-one/broker/${props.UserData.CustomerId}/endpoint`))),
            }, patternInput[1].t("settings.sso.redirect_uri", arg_1)),
        })), delay(() => append(singleton(Checkbox({
            ComponentState: componentState,
            IsChecked: props.SSOConfiguration.SSOActive,
            Label: t("settings.sso.active"),
            OnCheck: (value_2) => {
                props.Dispatch(new Msg(0, [value_2]));
            },
            TestId: "sso-active",
        })), delay(() => append(props.SSOConfiguration.SSOActive ? append(singleton(createElement(Input, {
            ComponentState: componentState,
            Label: t("settings.sso.client_id"),
            OnChange: (arg_2) => {
                props.Dispatch(new Msg(1, [arg_2]));
            },
            TestId: "sso-client-id",
            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "client_id")),
            Value: props.SSOConfiguration.ClientId,
        })), delay(() => append(singleton(createElement(Input, {
            ComponentState: componentState,
            Label: t("settings.sso.client_secret"),
            OnChange: (arg_3) => {
                props.Dispatch(new Msg(2, [arg_3]));
            },
            TestId: "sso-client-secret",
            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "client_secret")),
            Value: props.SSOConfiguration.ClientSecret,
        })), delay(() => append(singleton(createElement(Input, {
            ComponentState: componentState,
            Label: t("settings.sso.open_id_connect_discovery_url"),
            OnChange: (arg_4) => {
                props.Dispatch(new Msg(3, [arg_4]));
            },
            TestId: "sso-open-id-connect-discovery-url",
            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "open_id_connect_discovery_url")),
            Value: props.SSOConfiguration.OpenIdConnectDiscoveryUrl,
        })), delay(() => singleton(createElement(Input, {
            ComponentState: componentState,
            Label: t("settings.sso.allowed_hosts"),
            OnChange: (arg_5) => {
                props.Dispatch(new Msg(4, [arg_5]));
            },
            TestId: "sso-allowed_hosts",
            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "allowed_hosts")),
            Value: props.SSOConfiguration.AllowedHosts,
        })))))))) : empty(), delay(() => {
            let matchValue;
            return append((matchValue = FormState__getValidation(props.FormState, t, "global"), (matchValue == null) ? (empty()) : singleton(createElement(ErrorAlert, {
                Label: matchValue,
            }))), delay(() => {
                let elems;
                return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-end"])], (elems = [createElement(Button, {
                    ComponentState: componentState,
                    Label: t("general.save"),
                    OnClick: () => {
                        props.Dispatch(new Msg(6, []));
                    },
                    TestId: "update-sso-configuration",
                    Variant: "primary",
                })], ["children", reactApi.Children.toArray(Array.from(elems))])]))));
            }));
        }))))));
    })), ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function SSOSettings(props) {
    let elems;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init_1, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const matchValue = state_1.SSOConfiguration;
    if (matchValue.tag === 0) {
        return defaultOf();
    }
    else {
        return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-5"])], (elems = [createElement(SSOConfiguration, {
            Dispatch: patternInput[1],
            FormState: state_1.FormState,
            SSOConfiguration: matchValue.fields[0],
            UserData: props.UserData,
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
    }
}

