import { Helper_shoppingCartPriceSumString, PackageUnit, Helper_sizeLargeLabel, Helper_sizeMediumLabel, QRCodeSize, Helper_sizeSmallLabel, Helper_pricePerPosition, Helper_packageUnitString, Helper_qrCodeSizeString } from "../Shared/Shop.js";
import { equals, createObj, int32ToString } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { Msg, ShoppingCartPositionRow } from "./Types.js";
import { useTranslation } from "react-i18next";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { TableContainer, SectionContainer } from "../Widgets/Container.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { Variant as Variant_2 } from "../Variant.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { isEmpty, map, toArray, empty, find, ofArray, singleton } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { TextValue, SectionHeader } from "../Components/Text.js";
import { Select } from "../Components/Select.js";
import { NumberInput } from "../Components/Input.js";
import { numberInputIntMaxValue } from "../Common.js";
import { toString } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { TextButton, Button } from "../Components/Button.js";
import { BillingData, PaymentPeriod, BillingMethod, BillingMethodInvoice } from "../Shared/Shared.js";
import { utcNow } from "../fable_modules/fable-library-js.4.19.2/Date.js";
import { OnTableRowClick$1, ColumnDefinition$1, CellContent, Table } from "../Widgets/Table/Table.js";
import { ConfirmationDialog } from "../Components/Dialog.js";
import { ConfirmOrderDialog } from "./ConfirmOrderDialog.js";
import { unwrap } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { update as update_1, init as init_1 } from "./State.js";
import { singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { InformationAlert } from "../Components/Alert.js";

export function toOrderPositionRow(orderPosition) {
    return new ShoppingCartPositionRow(orderPosition.Id, Helper_qrCodeSizeString(orderPosition.QRCodeSize), Helper_packageUnitString(orderPosition.PackageUnit), orderPosition.Amount, int32ToString(Helper_pricePerPosition(orderPosition)) + ",00 €", Helper_pricePerPosition(orderPosition));
}

function InformationSection() {
    let elems_1, elems, children;
    const t = useTranslation()[0];
    return createElement(SectionContainer, {
        Content: createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-1", "gap-4"])], (elems_1 = [createElement("div", createObj(singleton((elems = [createElement("img", {
            className: join(" ", ["h-44"]),
            src: (Variant_2.tag === 2) ? "assets/images/qr_codes_i24.jpeg" : ((Variant_2.tag === 0) ? "assets/images/qr_codes.jpg" : "assets/images/qr_codes_i24.jpeg"),
        })], ["children", reactApi.Children.toArray(Array.from(elems))])))), (children = ofArray([createElement(SectionHeader, {
            Text: t("general.description"),
        }), createElement(TextValue, {
            FontWeight: "normal",
            TestId: "",
            Text: t("shop.qr_code_description"),
        })]), createElement("div", {
            children: reactApi.Children.toArray(Array.from(children)),
        }))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))),
    });
}

function FormView(props) {
    let elems_1, elems;
    const t = useTranslation()[0];
    const sizeOptions = ofArray([{
        label: Helper_sizeSmallLabel,
        value: new QRCodeSize(0, []),
    }, {
        label: Helper_sizeMediumLabel,
        value: new QRCodeSize(1, []),
    }, {
        label: Helper_sizeLargeLabel,
        value: new QRCodeSize(2, []),
    }]);
    const packageUnitOptions = ofArray([{
        label: "250 Stück (100,00€)",
        value: new PackageUnit(0, []),
    }, {
        label: "500 Stück (180,00€)",
        value: new PackageUnit(1, []),
    }, {
        label: "1.000 Stück (300,00€)",
        value: new PackageUnit(2, []),
    }]);
    return createElement(SectionContainer, {
        Content: createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-1", "gap-4"])], (elems_1 = [createElement(Select, {
            ComponentState: "enabled",
            IsClearable: false,
            Label: t("shop.size"),
            NoOptionsMessage: "",
            OnChange: (selectedSize) => {
                if (selectedSize == null) {
                }
                else {
                    props.Dispatch(new Msg(7, [selectedSize.value]));
                }
            },
            Options: sizeOptions,
            PlaceholderKey: "",
            TestId: "",
            Value: find((option) => equals(option.value, props.State.CurrentShoppingCartPosition.QRCodeSize), sizeOptions),
        }), createElement(Select, {
            ComponentState: "enabled",
            IsClearable: false,
            Label: t("shop.packaging_unit"),
            NoOptionsMessage: "",
            OnChange: (selectedPackageUnit) => {
                if (selectedPackageUnit == null) {
                }
                else {
                    props.Dispatch(new Msg(8, [selectedPackageUnit.value]));
                }
            },
            Options: packageUnitOptions,
            PlaceholderKey: "",
            TestId: "",
            Value: find((option_1) => equals(option_1.value, props.State.CurrentShoppingCartPosition.PackageUnit), packageUnitOptions),
        }), createElement(NumberInput, {
            ComponentState: "enabled",
            Label: t("shop.max_quantity"),
            MaxValue: numberInputIntMaxValue,
            OnChange: (e) => {
                props.Dispatch(new Msg(9, [undefined, toString(e)]));
            },
            TestId: "",
            Value: props.State.CurrentShoppingCartPosition.Amount,
        }), createElement("div", createObj(ofArray([["className", join(" ", ["mt-3", "ml-auto"])], (elems = [createElement(Button, {
            ComponentState: "enabled",
            Label: t("shop.add_to_shoppingcart"),
            OnClick: () => {
                props.Dispatch(new Msg(0, []));
            },
            TestId: "shopaddtoshoppingcart-button",
            Variant: "primary",
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))),
    });
}

function ShoppingCartView(props) {
    let elems, elems_3, elems_2, elems_1, Title_1;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(undefined);
    const setSelectedRowId = patternInput_1[1];
    const selectedRowId = patternInput_1[0];
    const patternInput_2 = reactApi.useState(false);
    const setIsConfirmOrderDialogOpen = patternInput_2[1];
    let billingData_1;
    const matchValue = props.State.BillingData;
    billingData_1 = ((matchValue == null) ? (new BillingData(1, new BillingMethod(1, [new BillingMethodInvoice(false)]), new PaymentPeriod(0, []), utcNow(), utcNow(), utcNow())) : matchValue);
    let sectionContent;
    const children = ofArray([createElement("div", createObj(singleton((elems = [createElement(Table, {
        ColumnDefinitions: [new ColumnDefinition$1("size", "", t("shop.size"), (row) => (new CellContent(0, [row.Size])), "none", "text"), new ColumnDefinition$1("packageUnit", "", t("shop.packaging_unit"), (row_1) => (new CellContent(0, [row_1.PackageUnit])), "none", "text"), new ColumnDefinition$1("amount", "", t("general.quantity_3"), (row_2) => (new CellContent(0, [int32ToString(row_2.Amount)])), "none", "text"), new ColumnDefinition$1("pricePerPositionAsString", "", t("shop.price_per_position"), (row_3) => (new CellContent(0, [row_3.PricePerPositionAsString])), "none", "text"), new ColumnDefinition$1("action", "", t("general.action"), (row_4) => (new CellContent(1, [createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.remove"),
            OnClick: () => {
                setSelectedRowId(row_4.Id);
            },
            TestId: "",
            Variant: "default",
        })])), "none", "text")],
        Controls: empty(),
        Options: {
            BorderStyle: "full",
            CSVDownload: false,
            ConfigurableColumns: false,
            DefaultSortColumn: ["size", "asc"],
            GlobalSearch: false,
            OnRowClick: new OnTableRowClick$1(0, [(row_5) => {
                setSelectedRowId(row_5.Id);
            }]),
            RowSelection: "disabled",
        },
        TableRows: toArray(map(toOrderPositionRow, props.State.ShoppingCart.ShoppingCartPositions)),
    })], ["children", reactApi.Children.toArray(Array.from(elems))])))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "m-4"])], (elems_3 = [createElement(TextValue, {
        FontWeight: "small",
        TestId: "",
        Text: t("shop.shipping_costs"),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["ml-auto"])], (elems_2 = [createElement("label", {
        children: t("shop.sum_plus_vat"),
    }), createElement(TextValue, {
        FontWeight: "bold",
        TestId: "",
        Text: `${Helper_shoppingCartPriceSumString(props.State.ShoppingCart.ShoppingCartPositions)}`,
    }), createElement("div", createObj(ofArray([["className", join(" ", ["mt-4"])], (elems_1 = [createElement(Button, {
        ComponentState: isEmpty(props.State.ShoppingCart.ShoppingCartPositions) ? "disabled" : "enabled",
        Label: t("shop.order_shoppingcart"),
        OnClick: () => {
            setIsConfirmOrderDialogOpen(true);
        },
        TestId: "shopaddtoshoppingcart-button",
        Variant: "primary",
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))]);
    sectionContent = createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    });
    const xs_6 = [createElement(ConfirmationDialog, {
        Description: "",
        IsOpen: selectedRowId != null,
        OnClose: () => {
            setSelectedRowId(undefined);
        },
        OnConfirm: () => {
            if (selectedRowId == null) {
            }
            else {
                const selectedRowId_1 = selectedRowId;
                setSelectedRowId(undefined);
                props.Dispatch(new Msg(1, [selectedRowId_1]));
            }
        },
        Title: t("general.remove"),
    }), createElement(ConfirmOrderDialog, {
        BillingData: billingData_1,
        IsOpen: patternInput_2[0],
        OnClose: () => {
            setIsConfirmOrderDialogOpen(false);
        },
        ShoppingCart: props.State.ShoppingCart,
        SuccessCallback: () => {
            props.Dispatch(new Msg(10, []));
        },
    }), createElement(TableContainer, (Title_1 = t("shop.shopping_cart"), {
        Content: createElement("div", {
            className: join(" ", ["flex", "flex-col"]),
            children: reactApi.Children.toArray([sectionContent]),
        }),
        Title: unwrap(Title_1),
    }))];
    return react.createElement(react.Fragment, {}, ...xs_6);
}

export function BarcodeShopView(props) {
    let elems_2, elems_1;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init_1, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    return createElement("div", createObj(ofArray([["id", "shop-container"], ["className", "flex-grow-1 d-flex flex-column"], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-2", "gap-4"])], (elems_1 = toList(delay(() => {
        const matchValue = props.AccountSettings.AccountState;
        switch (matchValue) {
            case "active":
                return append(singleton_1(createElement(FormView, {
                    Dispatch: dispatch,
                    State: state_1,
                })), delay(() => append(singleton_1(InformationSection()), delay(() => {
                    let elems;
                    return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["col-span-2"])], (elems = [createElement(ShoppingCartView, {
                        Dispatch: dispatch,
                        State: state_1,
                    })], ["children", reactApi.Children.toArray(Array.from(elems))])]))));
                }))));
            default:
                return singleton_1(createElement(InformationAlert, {
                    Label: patternInput_1[0]("shop.only_active_for_customers"),
                }));
        }
    })), ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
}

