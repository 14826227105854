import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, union_type, class_type, list_type, float64_type, bool_type, option_type, anonRecord_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Helper_receiverTypeNameTranslation, ReceiverTypeName, User_$reflection, Receiver_$reflection } from "../../Shared/User.js";
import { Storage_$reflection } from "../../Shared/Storage.js";
import { FormState__getValidation, FormState__startLoading, AssignConsumableV2Dto, ConsumableId, AssignConsumableWithNotificationDto, FormState__setValidationResponse_5219762A, UserId, RequestedValue$1, FormState_get_empty, RequestedValue$1_$reflection, FormState_$reflection, ClientApiResponse$1_$reflection } from "../../Shared/Shared.js";
import { ConsumableDetailDto_$reflection } from "../../Shared/Consumable.js";
import { Cmd_ofEffect, Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { sortBy, toArray, isEmpty, sum, tryFind, contains, map, filter, ofArray, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { unwrapUserId } from "../../Shared/Helper.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { safeHash, comparePrimitives, createObj, stringHash, equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { doRangesOverlap, unwrapStorageId } from "../../Shared/Helper.js";
import { unwrap, map as map_1, defaultArg } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { assignCmd, assignWithNotificationCmd } from "../../Requests/Consumable.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { addDays, today } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { ErrorAlert, InformationAlert, WarningAlert } from "../../Components/Alert.js";
import { GroupedSelect, Select } from "../../Components/Select.js";
import { List_groupBy } from "../../fable_modules/fable-library-js.4.19.2/Seq2.js";
import { TextArea } from "../../Components/TextArea.js";
import { NumberInput } from "../../Components/Input.js";
import { Checkbox } from "../../Components/Checkbox.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { TextButton } from "../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetSelectedReceiver", "SetSelectedStorage", "SetWithNotification", "SetWithSignature", "SetComment", "SetQuantity", "AssignConsumableResponse", "AssignConsumable", "ReceiverFetched", "ReceiversFetched", "StoragesFetched", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Consumables.AssignConsumableDialog.Msg", [], Msg, () => [[["Item", option_type(anonRecord_type(["label", string_type], ["value", Receiver_$reflection()]))]], [["Item", option_type(anonRecord_type(["label", string_type], ["value", Storage_$reflection()]))]], [["Item", bool_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", option_type(float64_type)]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [], [["Item", User_$reflection()]], [["Item", list_type(Receiver_$reflection())]], [["Item", list_type(Storage_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(FormState, SuccessCallback, OnClose, Consumable, WithNotification, WithSignature, Quantity, Comment$, ReceiverId, SelectedReceiver, SelectedStorage, IsSelectedReceiverInvalid, IsSelectedStorageInvalid, Receiver, Receivers, Storages) {
        super();
        this.FormState = FormState;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.Consumable = Consumable;
        this.WithNotification = WithNotification;
        this.WithSignature = WithSignature;
        this.Quantity = Quantity;
        this.Comment = Comment$;
        this.ReceiverId = ReceiverId;
        this.SelectedReceiver = SelectedReceiver;
        this.SelectedStorage = SelectedStorage;
        this.IsSelectedReceiverInvalid = IsSelectedReceiverInvalid;
        this.IsSelectedStorageInvalid = IsSelectedStorageInvalid;
        this.Receiver = Receiver;
        this.Receivers = Receivers;
        this.Storages = Storages;
    }
}

function State_$reflection() {
    return record_type("Consumables.AssignConsumableDialog.State", [], State, () => [["FormState", FormState_$reflection()], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["Consumable", ConsumableDetailDto_$reflection()], ["WithNotification", bool_type], ["WithSignature", bool_type], ["Quantity", option_type(float64_type)], ["Comment", string_type], ["ReceiverId", string_type], ["SelectedReceiver", option_type(anonRecord_type(["label", string_type], ["value", Receiver_$reflection()]))], ["SelectedStorage", option_type(anonRecord_type(["label", string_type], ["value", Storage_$reflection()]))], ["IsSelectedReceiverInvalid", bool_type], ["IsSelectedStorageInvalid", bool_type], ["Receiver", RequestedValue$1_$reflection(User_$reflection())], ["Receivers", RequestedValue$1_$reflection(list_type(Receiver_$reflection()))], ["Storages", RequestedValue$1_$reflection(list_type(Storage_$reflection()))]]);
}

function init(consumable, successCallback, onClose, receiverId) {
    return [new State(FormState_get_empty(), successCallback, onClose, consumable, false, false, undefined, "", receiverId, undefined, undefined, false, false, new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, [])), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), User_$reflection()));
    })))), `/api/receivers/users/${unwrapUserId(new UserId(receiverId))}`, (Item) => (new Msg(8, [Item])), (Item_1) => (new Msg(11, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Receiver_$reflection())));
    })))), "/api/receivers/active", (Item_2) => (new Msg(9, [Item_2])), (Item_3) => (new Msg(11, [Item_3]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_6), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
        const pr_2 = response_2.arrayBuffer();
        return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(Storage_$reflection())));
    })))), "/api/storages/access", (Item_4) => (new Msg(10, [Item_4])), (Item_5) => (new Msg(11, [Item_5])))]))];
}

function update(msg, state) {
    switch (msg.tag) {
        case 0:
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Consumable, state.WithNotification, state.WithSignature, state.Quantity, state.Comment, state.ReceiverId, msg.fields[0], state.SelectedStorage, state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, state.Receiver, state.Receivers, state.Storages), Cmd_none()];
        case 1:
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Consumable, state.WithNotification, state.WithSignature, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, msg.fields[0], state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, state.Receiver, state.Receivers, state.Storages), Cmd_none()];
        case 2: {
            const withNotification = msg.fields[0];
            if (!withNotification) {
                return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Consumable, withNotification, false, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, state.Receiver, state.Receivers, state.Storages), Cmd_none()];
            }
            else {
                return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Consumable, withNotification, state.WithSignature, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, state.Receiver, state.Receivers, state.Storages), Cmd_none()];
            }
        }
        case 3: {
            const withSignature = msg.fields[0];
            if (withSignature) {
                return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Consumable, true, withSignature, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, state.Receiver, state.Receivers, state.Storages), Cmd_none()];
            }
            else {
                return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Consumable, state.WithNotification, withSignature, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, state.Receiver, state.Receivers, state.Storages), Cmd_none()];
            }
        }
        case 4:
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Consumable, state.WithNotification, state.WithSignature, state.Quantity, msg.fields[0], state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, state.Receiver, state.Receivers, state.Storages), Cmd_none()];
        case 5:
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Consumable, state.WithNotification, state.WithSignature, msg.fields[0], state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, state.Receiver, state.Receivers, state.Storages), Cmd_none()];
        case 9:
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Consumable, state.WithNotification, state.WithSignature, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, state.Receiver, new RequestedValue$1(1, [msg.fields[0]]), state.Storages), Cmd_none()];
        case 6: {
            const response_1 = msg.fields[0];
            if (response_1.tag === 1) {
                return [new State(FormState__setValidationResponse_5219762A(state.FormState, response_1.fields[0]), state.SuccessCallback, state.OnClose, state.Consumable, state.WithNotification, state.WithSignature, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, state.Receiver, state.Receivers, state.Storages), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(FormState_get_empty(), state.SuccessCallback, state.OnClose, state.Consumable, state.WithNotification, state.WithSignature, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, state.Receiver, state.Receivers, state.Storages), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_successToast("general.saved_successfully"), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                })]))];
            }
        }
        case 7: {
            const matchValue = state.SelectedReceiver;
            const matchValue_1 = state.SelectedStorage;
            if (matchValue == null) {
                if (matchValue_1 != null) {
                    return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Consumable, state.WithNotification, state.WithSignature, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, true, false, state.Receiver, state.Receivers, state.Storages), Cmd_none()];
                }
                else {
                    return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Consumable, state.WithNotification, state.WithSignature, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, true, true, state.Receiver, state.Receivers, state.Storages), Cmd_none()];
                }
            }
            else if (matchValue_1 == null) {
                return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Consumable, state.WithNotification, state.WithSignature, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, false, true, state.Receiver, state.Receivers, state.Storages), Cmd_none()];
            }
            else {
                const receiver_1 = matchValue;
                const storage = matchValue_1;
                let cmd;
                if (state.WithNotification && (equals(receiver_1.value.ReceiverType, new ReceiverTypeName(0, [])) ? true : (receiver_1.value.Email != null))) {
                    const dto = new AssignConsumableWithNotificationDto(receiver_1.value.Id, unwrapStorageId(storage.value.Id), state.Comment, "", defaultArg(state.Quantity, 0), state.WithSignature, "storageToUser");
                    cmd = assignWithNotificationCmd(new ConsumableId(parse(state.Consumable.Id)), dto, (Item_1) => (new Msg(6, [Item_1])), (Item_2) => (new Msg(11, [Item_2])));
                }
                else {
                    const dto_1 = new AssignConsumableV2Dto(receiver_1.value.Id, unwrapStorageId(storage.value.Id), state.Comment, defaultArg(state.Quantity, 0), undefined, "");
                    cmd = assignCmd(new ConsumableId(parse(state.Consumable.Id)), dto_1, (Item_4) => (new Msg(6, [Item_4])), (Item_5) => (new Msg(11, [Item_5])));
                }
                return [new State(FormState__startLoading(state.FormState), state.SuccessCallback, state.OnClose, state.Consumable, state.WithNotification, state.WithSignature, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, false, false, state.Receiver, state.Receivers, state.Storages), cmd];
            }
        }
        case 10:
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Consumable, state.WithNotification, state.WithSignature, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, state.Receiver, state.Receivers, new RequestedValue$1(1, [msg.fields[0]])), Cmd_none()];
        case 11:
            return [state, Cmd_none()];
        default:
            return [new State(state.FormState, state.SuccessCallback, state.OnClose, state.Consumable, state.WithNotification, state.WithSignature, state.Quantity, state.Comment, state.ReceiverId, state.SelectedReceiver, state.SelectedStorage, state.IsSelectedReceiverInvalid, state.IsSelectedStorageInvalid, new RequestedValue$1(1, [msg.fields[0]]), state.Receivers, state.Storages), Cmd_none()];
    }
}

function targetStorages(user, consumable, storages) {
    return filter((s) => {
        const source = map((sps) => sps.StorageId, consumable.StockPerStorage);
        return contains(unwrapStorageId(s.Id), source, {
            Equals: (x, y) => (x === y),
            GetHashCode: stringHash,
        });
    }, storages);
}

function DialogBody(props) {
    let quantity_1, stock_2, quantity, stock_1, elems, elems_3;
    const patternInput = useTranslation();
    const t = patternInput[0];
    const targetStorages_1 = targetStorages(props.User, props.Consumable, props.Storages);
    const storageOptions = map((storage) => ({
        label: storage.Name,
        value: storage,
    }), targetStorages_1);
    let isEnoughStock;
    const matchValue = props.SelectedStorage;
    if (matchValue == null) {
        isEnoughStock = true;
    }
    else {
        const storage_1 = matchValue;
        const stock = map_1((stockPerStorage_1) => stockPerStorage_1.Stock, tryFind((stockPerStorage) => (stockPerStorage.StorageId === unwrapStorageId(storage_1.value.Id)), props.Consumable.StockPerStorage));
        const reservedStock = sum(map((reservation_1) => reservation_1.Quantity, filter((reservation) => doRangesOverlap(today(), addDays(today(), 7), reservation.StartDate, reservation.EndDate), props.Consumable.Reservations)), {
            GetZero: () => 0,
            Add: (x, y) => (x + y),
        });
        const matchValue_1 = props.Quantity;
        isEnoughStock = ((stock == null) ? ((matchValue_1 == null) ? true : ((quantity_1 = matchValue_1, (reservedStock - quantity_1) >= 0))) : ((matchValue_1 == null) ? ((stock_2 = stock, (stock_2 - reservedStock) >= 0)) : ((quantity = matchValue_1, (stock_1 = stock, ((stock_1 - reservedStock) - quantity) >= 0)))));
    }
    if (isEmpty(targetStorages_1)) {
        return createElement("div", createObj(singleton((elems = [createElement("strong", {
            children: [t("consumable.dialog_assign_quantity_empty_storage")],
        }), createElement("p", {
            children: [t("consumable.dialog_assign_quantity_empty_storage_sub")],
        })], ["children", reactApi.Children.toArray(Array.from(elems))]))));
    }
    else {
        return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems_3 = toList(delay(() => {
            let elems_1, arg_1;
            return append(!isEnoughStock ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["max-w-[40rem]"])], (elems_1 = [createElement(WarningAlert, {
                Label: (arg_1 = {
                    dateString: "reminder.Date.ToString(\"dd.MM.yyyy\")",
                    reminderName: "reminder.Title",
                }, patternInput[1].t("tool.reservation_dialog.reservation_stock_to_low_warning", arg_1)),
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))) : empty(), delay(() => append(singleton_1(createElement(Select, {
                ComponentState: props.ComponentState,
                IsClearable: false,
                Label: t("consumable.choose_storage"),
                NoOptionsMessage: "select.storage_no_options",
                OnChange: (arg_2) => {
                    props.Dispatch(new Msg(1, [arg_2]));
                },
                Options: storageOptions,
                PlaceholderKey: "select.storage_placeholder",
                TestId: "",
                ValidationMessage: unwrap(props.IsStorageInvalid ? t("tool.switch_storage_missing_storage_validation") : undefined),
                Value: unwrap(props.SelectedStorage),
            })), delay(() => append(singleton_1(createElement(GroupedSelect, (($value) => { $value.key = $value.Key; return $value; })({
                ComponentState: props.ComponentState,
                IsClearable: false,
                Key: "select-user",
                Label: t("consumable.choose_receiver"),
                NoOptionsMessage: "select.employee_no_options",
                OnChange: (arg_3) => {
                    props.Dispatch(new Msg(0, [arg_3]));
                },
                Options: map((tupledArg) => {
                    const receiverType = tupledArg[0];
                    const receivers_1 = toArray(sortBy((receiver_2) => receiver_2.label.toLowerCase(), map((receiver_1) => {
                        const matchValue_4 = receiver_1.LocationName;
                        if (matchValue_4 != null) {
                            const locationName = matchValue_4;
                            if (equals(receiverType, new ReceiverTypeName(4, []))) {
                                return {
                                    label: `${locationName} - ${receiver_1.Name}`,
                                    value: receiver_1,
                                };
                            }
                            else {
                                return {
                                    label: `${receiver_1.Name} - ${locationName}`,
                                    value: receiver_1,
                                };
                            }
                        }
                        else {
                            return {
                                label: `${receiver_1.Name}`,
                                value: receiver_1,
                            };
                        }
                    }, filter((u) => {
                        if (props.WithNotification) {
                            const matchValue_3 = u.ReceiverType;
                            switch (matchValue_3.tag) {
                                case 1:
                                case 5:
                                case 8:
                                case 6:
                                case 2:
                                case 7:
                                case 3:
                                case 9:
                                case 4:
                                    return u.Email != null;
                                default:
                                    return true;
                            }
                        }
                        else {
                            return true;
                        }
                    }, tupledArg[1])), {
                        Compare: comparePrimitives,
                    }));
                    return {
                        label: Helper_receiverTypeNameTranslation(t, receiverType),
                        options: receivers_1,
                    };
                }, List_groupBy((receiver) => receiver.ReceiverType, props.Users, {
                    Equals: equals,
                    GetHashCode: safeHash,
                })),
                PlaceholderKey: "select.employee_placeholder",
                Value: unwrap(props.SelectedReceiver),
            }))), delay(() => append(singleton_1(createElement(TextArea, {
                ComponentState: props.ComponentState,
                Label: t("general.comment"),
                OnChange: (arg_4) => {
                    props.Dispatch(new Msg(4, [arg_4]));
                },
                TestId: "",
                Value: props.Comment,
            })), delay(() => append(singleton_1(createElement(NumberInput, {
                ComponentState: props.ComponentState,
                Label: t("general.quantity_3"),
                OnChange: (arg_5) => {
                    props.Dispatch(new Msg(5, [arg_5]));
                },
                TestId: "",
                ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "quantity")),
                Value: unwrap(props.Quantity),
            })), delay(() => {
                let Label_4, matchValue_5, selectedUser;
                return append(singleton_1(Checkbox((Label_4 = t("tool.dialog_assign_with_notification"), {
                    ComponentState: (matchValue_5 = props.SelectedReceiver, (matchValue_5 != null) ? ((selectedUser = matchValue_5, (props.ComponentState === "enabled") ? ((equals(selectedUser.value.ReceiverType, new ReceiverTypeName(0, [])) ? true : (selectedUser.value.Email != null)) ? "enabled" : "disabled") : props.ComponentState)) : props.ComponentState),
                    IsChecked: props.WithNotification,
                    Label: Label_4,
                    OnCheck: (arg_6) => {
                        props.Dispatch(new Msg(2, [arg_6]));
                    },
                    TestId: "assign-tool-dialog-with-notification-test-id",
                }))), delay(() => {
                    let elems_2;
                    return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_2 = [createElement(InformationAlert, {
                        Label: t("tool.dialog_assign_with_notification_info"),
                    })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                        let Label_5, matchValue_7, selectedUser_1;
                        return append(singleton_1(Checkbox((Label_5 = t("tool.dialog_assign_with_signature"), {
                            ComponentState: (matchValue_7 = props.SelectedReceiver, (matchValue_7 != null) ? ((selectedUser_1 = matchValue_7, (props.ComponentState === "enabled") ? ((equals(selectedUser_1.value.ReceiverType, new ReceiverTypeName(0, [])) ? true : (selectedUser_1.value.Email != null)) ? "enabled" : "disabled") : props.ComponentState)) : props.ComponentState),
                            IsChecked: props.WithSignature,
                            Label: Label_5,
                            OnCheck: (arg_7) => {
                                props.Dispatch(new Msg(3, [arg_7]));
                            },
                            TestId: "assign-tool-dialog-with-notification-test-id",
                        }))), delay(() => {
                            const matchValue_9 = FormState__getValidation(props.FormState, t, "global");
                            if (matchValue_9 == null) {
                                return empty();
                            }
                            else {
                                return singleton_1(createElement(ErrorAlert, {
                                    Label: matchValue_9,
                                }));
                            }
                        }));
                    }));
                }));
            }))))))))));
        })), ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
    }
}

export function AssignConsumableDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init(props.Consumable, props.SuccessCallback, props.OnClose, props.UserId);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const componentState = state_1.FormState.IsLoading ? "disabled" : "enabled";
    return createElement(Dialog, (Title = t("consumable.assign_consumable"), {
        Body: toList(delay(() => {
            const matchValue = state_1.Receiver;
            const matchValue_1 = state_1.Receivers;
            const matchValue_2 = state_1.Storages;
            let matchResult, receivers, storages, user;
            if (matchValue.tag === 1) {
                if (matchValue_1.tag === 1) {
                    if (matchValue_2.tag === 1) {
                        matchResult = 1;
                        receivers = matchValue_1.fields[0];
                        storages = matchValue_2.fields[0];
                        user = matchValue.fields[0];
                    }
                    else {
                        matchResult = 0;
                    }
                }
                else {
                    matchResult = 0;
                }
            }
            else {
                matchResult = 0;
            }
            switch (matchResult) {
                case 0: {
                    return empty();
                }
                default:
                    return singleton_1(createElement(DialogBody, {
                        Comment: state_1.Comment,
                        ComponentState: componentState,
                        Consumable: state_1.Consumable,
                        Dispatch: dispatch,
                        FormState: state_1.FormState,
                        IsStorageInvalid: state_1.IsSelectedStorageInvalid,
                        Quantity: unwrap(state_1.Quantity),
                        SelectedReceiver: unwrap(state_1.SelectedReceiver),
                        SelectedStorage: unwrap(state_1.SelectedStorage),
                        Storages: storages,
                        User: user,
                        Users: receivers,
                        WithNotification: state_1.WithNotification,
                        WithSignature: state_1.WithSignature,
                    }));
            }
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: componentState,
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-assign-consumable-dialog-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: componentState,
            Label: t("general.assign"),
            OnClick: () => {
                dispatch(new Msg(7, []));
            },
            TestId: "save-assign-consumable-dialog-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

