import { Union } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { toLocalTime, toString } from "../fable_modules/fable-library-js.4.19.2/Date.js";
import { mapIndexed, concat, singleton as singleton_1, sortWith, cons, filter, contains, ofArray, collect, map } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { map as map_1, defaultArg } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { priceToString } from "../Common.js";
import { parse } from "../fable_modules/fable-library-js.4.19.2/Int32.js";
import { createElement, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { stringHash, createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Dialog } from "../Components/Dialog.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { TextValue } from "../Components/Text.js";
import { Checkbox } from "../Components/Checkbox.js";
import { sortAsc } from "../SharedComponents/NaturalOrder.js";
import { List_groupBy } from "../fable_modules/fable-library-js.4.19.2/Seq2.js";
import { TextButton } from "../Components/Button.js";

export class CustomField extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Link"];
    }
}

export function CustomField_$reflection() {
    return union_type("Widgets.QuickOverviewList.CustomField", [], CustomField, () => [[]]);
}

function mapProperties(props) {
    let Label_7, Label_8, Value_8, p, Label_9, Value_9, Label_10, Value_10, Label_11;
    const t = props.T;
    const purchaseDate = (d) => {
        if (d == null) {
            return "";
        }
        else {
            return toString(toLocalTime(d), "dd.MM.yyyy");
        }
    };
    const customProperties = map((propertyConfiguration) => {
        const matchValue = propertyConfiguration.Type;
        switch (matchValue) {
            case "multiEnum": {
                const Value_1 = defaultArg(propertyConfiguration.TextValue, "");
                return {
                    Group: t("tool.custom_properties"),
                    Key: propertyConfiguration.PropertyConfigurationId,
                    Label: propertyConfiguration.Label,
                    Value: Value_1,
                };
            }
            case "text": {
                const Value_2 = defaultArg(propertyConfiguration.TextValue, "");
                return {
                    Group: t("tool.custom_properties"),
                    Key: propertyConfiguration.PropertyConfigurationId,
                    Label: propertyConfiguration.Label,
                    Value: Value_2,
                };
            }
            case "date": {
                const Value_3 = defaultArg(map_1((value_3) => toString(value_3, "dd.MM.yyyy"), propertyConfiguration.DateValue), "");
                return {
                    Group: t("tool.custom_properties"),
                    Key: propertyConfiguration.PropertyConfigurationId,
                    Label: propertyConfiguration.Label,
                    Value: Value_3,
                };
            }
            case "bool": {
                const Value_4 = defaultArg(map_1((property) => {
                    if (property) {
                        return t("general.yes");
                    }
                    else {
                        return t("general.no");
                    }
                }, propertyConfiguration.BoolValue), "");
                return {
                    Group: t("tool.custom_properties"),
                    Key: propertyConfiguration.PropertyConfigurationId,
                    Label: propertyConfiguration.Label,
                    Value: Value_4,
                };
            }
            case "url": {
                const Value_5 = defaultArg(propertyConfiguration.TextValue, "");
                return {
                    CustomField: new CustomField(),
                    Group: t("tool.custom_properties"),
                    Key: propertyConfiguration.PropertyConfigurationId,
                    Label: propertyConfiguration.Label,
                    Value: Value_5,
                };
            }
            case "currency": {
                const Value_6 = defaultArg(map_1((arg) => priceToString(parse(arg, 511, false, 32)), propertyConfiguration.TextValue), "");
                return {
                    Group: t("tool.custom_properties"),
                    Key: propertyConfiguration.PropertyConfigurationId,
                    Label: propertyConfiguration.Label,
                    Value: Value_6,
                };
            }
            default: {
                const Value = defaultArg(propertyConfiguration.TextValue, "");
                return {
                    Group: t("tool.custom_properties"),
                    Key: propertyConfiguration.PropertyConfigurationId,
                    Label: propertyConfiguration.Label,
                    Value: Value,
                };
            }
        }
    }, props.Elements.CustomProperties);
    return collect((x) => x, ofArray([props.Elements.Custom, ofArray([(Label_7 = t("general.supplier_article_number"), {
        Group: t("tool.commercial_data"),
        Key: "commercial-data-supplier-article-number-fav-field",
        Label: Label_7,
        Value: props.Elements.CommercialData.SupplierArticleNumber,
    }), (Label_8 = t("general.purchase_price"), (Value_8 = ((p = props.Elements.CommercialData.PurchasePrice, (p == null) ? "" : priceToString(p))), {
        Group: t("tool.commercial_data"),
        Key: "commercial-data-purchaseprice-fav-field",
        Label: Label_8,
        Value: Value_8,
    })), (Label_9 = t("general.purchase_date"), (Value_9 = purchaseDate(props.Elements.CommercialData.PurchaseDate), {
        Group: t("tool.commercial_data"),
        Key: "manufacommercial-data-purchasedate-fav-field",
        Label: Label_9,
        Value: Value_9,
    })), (Label_10 = t("general.gurantee_date"), (Value_10 = purchaseDate(props.Elements.CommercialData.WarrantyDate), {
        Group: t("tool.commercial_data"),
        Key: "commercial-data-guranteedate-fav-field",
        Label: Label_10,
        Value: Value_10,
    })), (Label_11 = t("general.comment"), {
        Group: t("tool.commercial_data"),
        Key: "commercial-comment-fav-field",
        Label: Label_11,
        Value: props.Elements.CommercialData.Comment,
    })]), customProperties]));
}

export function QuickOverviewList(props) {
    let elems_4;
    const t = useTranslation()[0];
    const formState = props.IsLoading ? "disabled" : "enabled";
    const updatedProperties = mapProperties({
        Elements: props.Elements,
        T: t,
    });
    const patternInput_1 = useState(props.VisibleKeys);
    const updatedVisibleKeys = patternInput_1[0];
    const setUpdatedVisibleKeys = patternInput_1[1];
    const dependencies = [props.IsDialogOpen];
    reactApi.useEffect(() => {
        setUpdatedVisibleKeys((_arg_1) => props.VisibleKeys);
    }, dependencies);
    return createElement("div", createObj(singleton_1((elems_4 = toList(delay(() => {
        let Title, elems_2;
        return append(singleton(createElement(Dialog, (Title = t("general.quick_overview_customise"), {
            Body: singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "gap-5"])], (elems_2 = toList(delay(() => map((tupledArg) => {
                let elems_1;
                return createElement("div", createObj(singleton_1((elems_1 = toList(delay(() => {
                    let elems;
                    return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-2"])], (elems = [createElement(TextValue, {
                        FontWeight: "bold",
                        TestId: "",
                        Text: tupledArg[0],
                    })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => map((property_1) => Checkbox({
                        ComponentState: formState,
                        IsChecked: contains(property_1.Key, updatedVisibleKeys, {
                            Equals: (x_1, y_1) => (x_1 === y_1),
                            GetHashCode: stringHash,
                        }),
                        Label: property_1.Label,
                        OnCheck: (_arg_3) => {
                            setUpdatedVisibleKeys((_arg_4) => (contains(property_1.Key, updatedVisibleKeys, {
                                Equals: (x_2, y_2) => (x_2 === y_2),
                                GetHashCode: stringHash,
                            }) ? filter((key_3) => (key_3 !== property_1.Key), updatedVisibleKeys) : cons(property_1.Key, updatedVisibleKeys)));
                        },
                        TestId: "",
                    }), sortWith((globalPropertyA, globalPropertyB) => sortAsc(globalPropertyA.Label, globalPropertyB.Label), tupledArg[1]))));
                })), ["children", reactApi.Children.toArray(Array.from(elems_1))]))));
            }, List_groupBy((property) => property.Group, updatedProperties, {
                Equals: (x, y) => (x === y),
                GetHashCode: stringHash,
            })))), ["children", reactApi.Children.toArray(Array.from(elems_2))])])))),
            Controls: ofArray([createElement(TextButton, {
                ComponentState: formState,
                Label: t("general.cancel"),
                OnClick: () => {
                    props.SetDialogOpen(false);
                },
                TestId: "close-favorite-dialog-test-id",
                Variant: "blueButton",
            }), createElement(TextButton, {
                ComponentState: formState,
                Label: t("general.save"),
                OnClick: () => {
                    props.OnSave(updatedVisibleKeys);
                },
                TestId: "save-favorite-dialog-test-id",
                Variant: "blueButton",
            })]),
            IsOpen: props.IsDialogOpen,
            OnClose: () => {
                props.SetDialogOpen(false);
            },
            Title: Title,
        }))), delay(() => append(singleton(createElement("div", {
            className: join(" ", ["text-sm", "font-semibold", "mb-2"]),
            children: t("general.quick_overview"),
        })), delay(() => {
            let elems_3;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-2"])], (elems_3 = toList(delay(() => concat(mapIndexed((index, property_3) => toList(delay(() => append((index !== 0) ? singleton(createElement("div", {
                className: join(" ", ["col-span-2", "border-t", "border-divider"]),
            })) : empty(), delay(() => append(singleton(createElement("div", {
                className: join(" ", ["divide-y", "font-semibold", "text-sm", "text-black", "mr-5", "py-2"]),
                children: property_3.Label,
            })), delay(() => singleton(createElement("div", createObj(toList(delay(() => append(singleton(["className", join(" ", toList(delay(() => append(singleton("text-sm"), delay(() => append(singleton("text-base"), delay(() => append(singleton("py-2"), delay(() => append(singleton("break-words"), delay(() => append(singleton("whitespace-pre-wrap"), delay(() => {
                const matchValue = property_3.CustomField;
                if (matchValue == null) {
                    return empty();
                }
                else {
                    const customField = matchValue;
                    return singleton("hover:cursor-pointer");
                }
            })))))))))))))]), delay(() => append(singleton(["children", property_3.Value]), delay(() => append(singleton(["data-test-id", `${property_3.Key}-test-id`]), delay(() => {
                const matchValue_2 = property_3.CustomField;
                if (matchValue_2 == null) {
                    return empty();
                }
                else {
                    const customField_1 = matchValue_2;
                    return singleton(["onClick", (_arg_7) => {
                        window.open(property_3.Value, "_blank", "noreferrer");
                    }]);
                }
            }))))))))))))))))), filter((property_2) => contains(property_2.Key, props.VisibleKeys, {
                Equals: (x_3, y_3) => (x_3 === y_3),
                GetHashCode: stringHash,
            }), updatedProperties))))), ["children", reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => {
                const matchValue_4 = props.UserData.Role;
                return singleton(createElement(TextButton, {
                    ComponentState: "enabled",
                    Label: t("general.customize_list"),
                    OnClick: () => {
                        props.SetDialogOpen(true);
                    },
                    TestId: "update-overview-item-list-test-id",
                    Variant: "blueButton",
                }));
            }));
        }))));
    })), ["children", reactApi.Children.toArray(Array.from(elems_4))]))));
}

